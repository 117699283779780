import { useMemo } from 'react';
import FormControl from 'component/FormControl';
import Input from 'component/Input/Input';
import PasswordInput from 'component/Input/PasswordInput';
import DatePicker from 'component/Input/DatePicker';
import Select from 'component/Input/Select';
import Textarea from 'component/Input/Textarea';
import { FormHeading } from 'component/Typography';
import { FormRow } from 'layout/shared';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import api from 'api';
import storageKey from 'constant/storageKey';
import { useFormikContext } from 'formik';

function Flow1() {
  const { t } = useTranslation();
  const locale = localStorage.getItem(storageKey.LOCALE);
  const formik = useFormikContext();
  const { values, touched, errors } = formik;

  const cityQuery = useQuery('city', api.getCityList);
  const cityList = useMemo(() => {
    if (!cityQuery.isSuccess) return [];
    return cityQuery.data.map((city, i) => {
      return { label: city.name, value: i.toString() };
    });
  }, [cityQuery]);

  const contactTownList = useMemo(() => {
    if (!cityQuery.isSuccess) return [];
    if (!values.contactAddress) return [];
    if (!values.contactAddress.city) return [];
    const cityKey = values.contactAddress.city;
    const townList = cityQuery.data[cityKey].dist;
    return townList.map((town) => {
      return { label: town.name, value: town.c3 };
    });
  }, [cityQuery, values.contactAddress]);

  const registrantTownList = useMemo(() => {
    if (!cityQuery.isSuccess) return [];
    if (!values.registrantAddress) return [];
    if (!values.registrantAddress.city) return [];
    const cityKey = values.registrantAddress.city;
    const townList = cityQuery.data[cityKey].dist;
    return townList.map((town) => {
      return { label: town.name, value: town.c3 };
    });
  }, [cityQuery, values.registrantAddress]);
  return (
    <>
      <FormHeading>{t('common.flow.companyBasicInfo')}</FormHeading>
      <FormControl
        isRequired
        label={t('common.flow.applicant')}
        placement="top-left"
        isError={!!(touched.applicant && errors.applicant)}
        errorMessage={errors.applicant}
      >
        <Input
          placeholder={t('common.pleaseEnter')}
          id="applicant"
          {...formik.getFieldProps('applicant')}
        />
      </FormControl>
      <FormControl
        isRequired
        label={t('common.flow.loginMailbox')}
        placement="top-left"
        isError={!!(touched.email && errors.email)}
        errorMessage={errors.email}
      >
        <Input
          placeholder={t('common.pleaseEnter')}
          id="email"
          {...formik.getFieldProps('email')}
        />
      </FormControl>
      <FormControl
        isRequired
        label={t('common.flow.password')}
        placement="top-left"
        isError={!!(touched.password && errors.password)}
        errorMessage={errors.password}
      >
        <PasswordInput
          placeholder={t('common.pleaseEnter')}
          id="password"
          {...formik.getFieldProps('password')}
        />
      </FormControl>
      <FormControl
        isRequired
        label={t('register.flow1.confirmPassword')}
        placement="top-left"
        isError={!!(touched.confirmPassword && errors.confirmPassword)}
        errorMessage={errors.confirmPassword}
      >
        <PasswordInput
          placeholder={t('common.pleaseEnter')}
          id="confirmPassword"
          {...formik.getFieldProps('confirmPassword')}
        />
      </FormControl>
      <FormControl
        isRequired
        label={t('common.flow.contactAddress')}
        desc={t('register.flow1.mailingAddress')}
        tip={
          locale !== 'en-US' &&
          t('common.flow.postalCode', {
            postalCode: values?.contactAddress?.villagesAndTowns,
          })
        }
        placement="top-left"
        errorMessage={
          touched.contactAddress &&
          errors.contactAddress &&
          ((touched.contactAddress.city && errors.contactAddress.city) ||
            (touched.contactAddress.villagesAndTowns &&
              errors.contactAddress.villagesAndTowns) ||
            (touched.contactAddress.street && errors.contactAddress.street))
        }
      >
        {locale !== 'en-US' && (
          <FormRow>
            <Select
              placeholder={t('register.flow1.selectCountiesAndCities')}
              options={cityList}
              id="contactAddress.city"
              isError={
                !!(
                  touched.contactAddress &&
                  errors.contactAddress &&
                  touched.contactAddress.city &&
                  errors.contactAddress.city
                )
              }
              {...formik.getFieldProps('contactAddress.city')}
              onChange={(option) => {
                formik.setFieldValue('contactAddress.city', option.value);
                formik.setFieldValue('contactAddress.villagesAndTowns', '');
              }}
            />
            <Select
              placeholder={t('register.flow1.selectTownshipArea')}
              options={contactTownList}
              id="contactAddress.villagesAndTowns"
              isError={
                !!(
                  touched.contactAddress &&
                  errors.contactAddress &&
                  touched.contactAddress.villagesAndTowns &&
                  errors.contactAddress.villagesAndTowns
                )
              }
              {...formik.getFieldProps('contactAddress.villagesAndTowns')}
              onChange={(option) => {
                formik.setFieldValue(
                  'contactAddress.villagesAndTowns',
                  option.value,
                );
              }}
            />
          </FormRow>
        )}

        <Input
          placeholder={t('register.flow1.selectRoadName')}
          id="contactAddress.street"
          isError={
            !!(
              touched.contactAddress &&
              errors.contactAddress &&
              touched.contactAddress.street &&
              errors.contactAddress.street
            )
          }
          {...formik.getFieldProps('contactAddress.street')}
        />
      </FormControl>
      <FormControl
        isRequired
        label={t('common.flow.guiNumber')}
        placement="top-left"
        isError={!!(touched.tax && errors.tax)}
        errorMessage={errors.tax}
      >
        <Input
          placeholder={t('common.pleaseEnter')}
          id="tax"
          {...formik.getFieldProps('tax')}
        />
      </FormControl>
      <FormControl
        isRequired
        label={t('common.flow.established')}
        placement="top-left"
        isError={!!(touched.creationDate && errors.creationDate)}
        errorMessage={errors.creationDate}
      >
        <DatePicker
          placeholder={t('common.pleaseEnter')}
          id="creationDate"
          {...formik.getFieldProps('creationDate')}
          onChange={(d) => {
            formik.setFieldValue('creationDate', d);
          }}
        />
      </FormControl>
      <FormControl
        isRequired
        label={t('common.flow.numberOfEmployees')}
        placement="top-left"
        isError={!!(touched.numberOfEmployees && errors.numberOfEmployees)}
        errorMessage={errors.numberOfEmployees}
      >
        <Input
          placeholder={t('common.pleaseEnter')}
          id="numberOfEmployees"
          {...formik.getFieldProps('numberOfEmployees')}
        />
      </FormControl>
      <FormControl
        isRequired
        label={t('common.flow.capital')}
        placement="top-left"
        isError={!!(touched.capital && errors.capital)}
        errorMessage={errors.capital}
      >
        <Input
          placeholder={t('common.pleaseEnter')}
          id="capital"
          {...formik.getFieldProps('capital')}
        />
      </FormControl>
      <FormControl
        isRequired
        label={t('common.flow.registeredAddress')}
        tip={t('common.flow.postalCode', {
          postalCode: values?.registrantAddress?.villagesAndTowns,
        })}
        placement="top-left"
        errorMessage={
          touched.registrantAddress &&
          errors.registrantAddress &&
          ((touched.registrantAddress.city && errors.registrantAddress.city) ||
            (touched.registrantAddress.villagesAndTowns &&
              errors.registrantAddress.villagesAndTowns) ||
            (touched.registrantAddress.street &&
              errors.registrantAddress.street))
        }
      >
        {locale === 'en-US' ? (
          <></>
        ) : (
          <FormRow>
            <Select
              placeholder={t('register.flow1.selectCountiesAndCities')}
              options={cityList}
              id="registrantAddress.city"
              isError={
                !!(
                  touched.registrantAddress &&
                  errors.registrantAddress &&
                  touched.registrantAddress.city &&
                  errors.registrantAddress.city
                )
              }
              {...formik.getFieldProps('registrantAddress.city')}
              onChange={(option) => {
                formik.setFieldValue('registrantAddress.city', option.value);
                formik.setFieldValue('registrantAddress.villagesAndTowns', '');
              }}
            />
            <Select
              placeholder={t('register.flow1.selectTownshipArea')}
              options={registrantTownList}
              id="registrantAddress.villagesAndTowns"
              isError={
                !!(
                  touched.registrantAddress &&
                  errors.registrantAddress &&
                  touched.registrantAddress.villagesAndTowns &&
                  errors.registrantAddress.villagesAndTowns
                )
              }
              {...formik.getFieldProps('registrantAddress.villagesAndTowns')}
              onChange={(option) => {
                formik.setFieldValue(
                  'registrantAddress.villagesAndTowns',
                  option.value,
                );
              }}
            />
          </FormRow>
        )}
        <Input
          placeholder={t('register.flow1.selectRoadName')}
          id="registrantAddress.street"
          isError={
            !!(
              touched.registrantAddress &&
              errors.registrantAddress &&
              touched.registrantAddress.street &&
              errors.registrantAddress.street
            )
          }
          {...formik.getFieldProps('registrantAddress.street')}
        />
      </FormControl>
      <FormControl
        isRequired
        label={t('common.flow.companyProfile')}
        placement="top-left"
        isError={!!(touched.companyIntroduction && errors.companyIntroduction)}
        errorMessage={errors.companyIntroduction}
      >
        <Textarea
          placeholder={t('common.pleaseEnter')}
          id="companyIntroduction"
          {...formik.getFieldProps('companyIntroduction')}
        />
      </FormControl>
    </>
  );
}

export { Flow1 };
