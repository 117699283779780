function Percentage(x, y) {
  if (
    x === '' ||
    y === '' ||
    Number.isNaN(Number(x)) ||
    Number.isNaN(Number(y))
  )
    return '';
  return Math.round((Number(x) / Number(y)) * 100 * 100) / 100;
}
export default Percentage;
