import styled from 'styled-components/macro';

const Anchor = styled.div`
  position: ${(props) => (props.isActive ? 'relative' : 'relative')};
  inset: ${(props) => (props.isActive ? '0 0 auto auto' : '0 0 auto auto')};
  width: 58px;
  height: 58px;
  cursor: pointer;
  z-index: 99;
  ${(props) => props.theme.media.mobile_L} {
    position: absolute;
    inset: ${(props) =>
      props.isActive ? '50% 0 auto auto' : '50% 0 auto auto'};
    transform: translate(0, -50%);
  }
`;

const Line = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 3px;
  border-radius: 1px;
  background: ${(props) => props.theme.color.primary600};
  transition: 0.2s;
  &:nth-child(1) {
    top: ${(props) => (props.isActive ? '50%' : '0')};
    transform-origin: center center;
    transform: ${(props) =>
      props.isActive ? 'translate(0,-50%) rotate(45deg)' : 'unset'};
  }
  &:nth-child(2) {
    top: 50%;
    transform-origin: center center;
    transform: ${(props) =>
      props.isActive ? 'scale(0)' : 'translate(0, -50%)'};
    opacity: ${(props) => (props.isActive ? '0' : '1')};
  }
  &:nth-child(3) {
    top: ${(props) => (props.isActive ? '50%' : 'auto')};
    bottom: ${(props) => (props.isActive ? 'auto' : '0')};
    transform-origin: center center;
    transform: ${(props) =>
      props.isActive ? 'translate(0,-50%) rotate(-45deg)' : 'unset'};
  }
`;

const Button = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 16px;
  transform: translate(-50%, -50%);
`;

const MenuBurger = ({ isActive, onClick: onClickFunction }) => {
  return (
    <>
      <Anchor isActive={isActive} onClick={onClickFunction}>
        <Button>
          <Line isActive={isActive}></Line>
          <Line isActive={isActive}></Line>
          <Line isActive={isActive}></Line>
        </Button>
      </Anchor>
    </>
  );
};

export default MenuBurger;
