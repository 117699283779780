import styled from 'styled-components/macro';
import starImageUrl from 'asset/star.png';

export const Heading = styled.h2`
  display: inline-block;
  position: relative;
  color: #fff;
  font-size: 68px;
  font-weight: 700;
  letter-spacing: 0.1px;
  z-index: 97;
  ${(props) => props.theme.media.laptop_L} {
    font-size: 56px;
  }
  ${(props) => props.theme.media.laptop_M} {
    font-size: 48px;
  }
  ${(props) => props.theme.media.tablet_L} {
    font-size: 42px;
  }
  ${(props) => props.theme.media.tablet_M} {
    font-size: 38px;
  }
  ${(props) => props.theme.media.tablet_S} {
    font-size: 32px;
  }
  ${(props) => props.theme.media.mobile_L} {
    font-size: 20px;
    font-weight: 400;
    padding: 0 8px;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    height: 17px;
    inset: auto -18px -7px -5px;
    clip-path: polygon(0 0, 97% 0%, 100% 100%, 0% 100%);
    background: ${(props) => props.theme.color.primary500};
    transform: translate(0, -1px);
    z-index: -1;
    ${(props) => props.theme.media.laptop_M} {
      height: 15px;
      inset: auto -16px -7px -5px;
    }
    ${(props) => props.theme.media.tablet_L} {
      height: 13px;
      inset: auto -14px -7px -5px;
    }
    ${(props) => props.theme.media.tablet_S} {
      height: 5px;
      inset: auto -9px -3px -3px;
    }
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 19px;
    inset: auto -19px -7px -6px;
    clip-path: polygon(0 0, 97% 0%, 100% 100%, 0% 100%);
    background: #fff;
    z-index: -2;
    ${(props) => props.theme.media.laptop_M} {
      height: 17px;
      inset: auto -17px -7px -6px;
    }
    ${(props) => props.theme.media.tablet_L} {
      height: 15px;
      inset: auto -15px -7px -6px;
    }
    ${(props) => props.theme.media.tablet_S} {
      height: 7px;
      inset: auto -10px -3px -4px;
    }
  }
`;
const Star = styled.div`
  position: absolute;
  inset: 6px auto auto calc(100% + 18px);
  ${(props) => props.theme.media.laptop_M} {
    inset: 12px auto auto calc(100% + 18px);
  }
  ${(props) => props.theme.media.tablet_L} {
    inset: 10px auto auto calc(100% + 17px);
  }
  ${(props) => props.theme.media.tablet_M} {
    inset: 8px auto auto calc(100% + 16px);
  }
  ${(props) => props.theme.media.tablet_S} {
    display: none;
  }
  img {
    width: 44px;
    height: 45px;
    ${(props) => props.theme.media.laptop_M} {
      width: 24px;
      height: 25px;
    }
    ${(props) => props.theme.media.tablet_M} {
      width: 20px;
      height: 21px;
    }
    ${(props) => props.theme.media.tablet_S} {
      width: 15px;
      height: 16px;
    }
  }
`;
export const HeadingWrapper = styled.div`
  display: inline-block;
  position: relative;
  padding-left: 6px;
  padding-bottom: 7px;
`;

const PageHeading = (props) => {
  const { children } = props;

  return (
    <HeadingWrapper {...props}>
      {props.hasStar ? (
        <Star>
          <img src={starImageUrl} alt="star" />
        </Star>
      ) : null}
      <Heading>{children}</Heading>
    </HeadingWrapper>
  );
};
export { PageHeading };
