import React from 'react';

const TabPanels = ({ options, value }) => {
  const hitOption = options.find((option) => {
    return option.value === value;
  });
  return <>{hitOption ? hitOption.panel : 'not found'}</>;
};

const VisibleTabPanels = ({ options, value }) => {
  return (
    <>
      {options.map((option, i) => {
        const isHit = option.value === value;
        return (
          <div style={{ display: isHit ? 'block' : 'none' }} key={`panel${i}`}>
            {option.panel}
          </div>
        );
      })}
    </>
  );
};

TabPanels.defaultProps = {
  options: [],
  value: '',
};
VisibleTabPanels.defaultProps = {
  options: [],
  value: '',
};

export { TabPanels, VisibleTabPanels };
