const planId = {
  SUPPORT: '1',
  COOPERATIVE: '2',
  MARKETING: '3',
  1: 'support',
  2: 'cooperative',
  3: 'marketing',
};

const planOption = {
  SERIES: 'series',
  DOCUMENTARY: 'documentary',
  ANIME: 'anime',
  MARKETING: 'marketing',
};

export { planId, planOption };
