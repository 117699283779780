import React from 'react';
import styled, { css } from 'styled-components/macro';
import Tab from './Tab';

const leftStyle = css`
  align-items: flex-start;
`;
const centerStyle = css`
  align-items: center;
`;
const rightStyle = css`
  align-items: flex-end;
`;
const alignStyle = {
  left: leftStyle,
  center: centerStyle,
  right: rightStyle,
};

const hasNumberStyle = css`
  padding-top: 20px;
`;

const lockStyle = css`
  pointer-events: none;
`;

const StyledTabGroup = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 32px;
  ${(props) => props.theme.media.laptop_M} {
    padding-top: 20px;
  }
  ${(props) => props.theme.media.tablet_S} {
    gap: 20px;
    padding-top: 0;
    flex-direction: row;
    justify-content: space-between;
  }

  ${(props) => alignStyle[props.align] || leftStyle}
  ${(props) => props.hasNumber && hasNumberStyle}
  ${(props) => props.lock && lockStyle}
  ${(props) => props.theme.media.tablet_S} {
    display: none;
  }
`;

const Tabs = ({
  value,
  options,
  onChange,
  hasNumber,
  align,
  lock,
  ...props
}) => {
  return (
    <StyledTabGroup lock={lock} align={align} hasNumber={hasNumber} {...props}>
      {options.map((option, index) => (
        <Tab
          key={option.value}
          number={index + 1}
          label={option.label}
          hasNumber={hasNumber}
          isActive={option.value === value}
          status={option.status} // 如果完成無視順序綠綠，在 active 之前，如果有問題就紅紅紅 'complete'|'warning'|'none'
          onClick={() => onChange(option, index)}
        />
      ))}
    </StyledTabGroup>
  );
};

Tabs.defaultProps = {
  className: '',
  options: [],
  value: '',
  onChange: () => {},
};

export { Tabs };
