import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

const SubsidyCard = styled.div`
  margin-bottom: 20px;
  padding: 12px 24px;
  border-radius: 4px;
  background: ${(props) => props.theme.color.bluegray100};
  &:last-child {
    margin-bottom: 0;
  }
`;
const Content = styled.div`
  color: ${(props) => props.theme.color.bluegray900};
  font-size: 16px;
  line-height: 26px;
`;

const ConfirmSubsidy = ({ source }) => {
  const { t } = useTranslation();
  return (
    <>
      {source.map((data, i) => {
        return (
          <SubsidyCard key={i}>
            <Content>
              {t('memberApply.subsidy.year')}：{data.year}
            </Content>
            <Content>
              {t('memberApply.subsidy.subsidizedProjectName')}：
              {data.projectName}
            </Content>
            <Content>
              {t('memberApply.subsidy.subsidyAgency')}：{data.subsidyAgency}
            </Content>
            <Content>
              {t('memberApply.subsidy.subsidyAmount')}：{data.subsidyAmount}
            </Content>
          </SubsidyCard>
        );
      })}
    </>
  );
};

export default ConfirmSubsidy;
