import { useHistory } from 'react-router-dom';
import { FormHeading } from 'component/Typography';
import BlockHeading from 'component/BlockHeading';
import Table from 'component/Table';
import ConfirmUploadInfo from 'component/ConfirmInfo/ConfirmUploadInfo';
import ConfirmSubsidy from 'component/ConfirmInfo/ConfirmSubsidy';
import { useMedia } from 'react-use';
import { useTranslation } from 'react-i18next';

function Flow5(props) {
  const { formData } = props;
  const {
    registrationDocuments,
    nonMainlandCapitalDeclaration,
    balanceSheetForThePastYear,
    incomeStatementForThePastYear,
    cashFlowStatementForThePastYear,
    financialStatementOrFinancialVisa,
    creditReportOfUnitedCreditInformationCenterForThePastSixMonths,
    noRefundRecordForThePastSixMonths,
    certificateOfNoIllegalTaxOwingForThePastSixMonths,
    productionExperience,
    awardRecord,
    ventureCapitalExperience,
    intellectualPropertyRightsDevelopmentExperience,
    internationalMarket,
    internationalJointVenture,
    subsidiesInThePastThreeYears,
  } = formData.values;
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = history.location;
  const isRecommendMember = pathname === '/recommend-member-application/';
  const isTabletM = useMedia('(max-width: 678px)');

  return (
    <div>
      <FormHeading>{t('common.flow.docConfirmation')}</FormHeading>
      <BlockHeading>{t('common.flow.companyBasicInfo')}</BlockHeading>
      <Table
        columns={[
          {
            dataIndex: 'name',
            width: isTabletM ? '130px' : '192px',
          },
          {
            dataIndex: 'content',
            width: '100%',
          },
        ]}
        dataSource={[
          {
            name: t('memberApply.flow1.setRegisterDoc'),
            content: (
              <ConfirmUploadInfo
                path={registrationDocuments[0]?.fullPath}
                name={registrationDocuments[0]?.fileName}
                value={registrationDocuments}
              />
            ),
          },
          {
            name: t('memberApply.flow1.statement'),
            content: (
              <ConfirmUploadInfo
                path={nonMainlandCapitalDeclaration[0]?.fullPath}
                name={nonMainlandCapitalDeclaration[0]?.fileName}
                value={nonMainlandCapitalDeclaration}
              />
            ),
          },
          {
            name: t('memberApply.flow1.balanceSheet'),
            content: (
              <ConfirmUploadInfo
                path={balanceSheetForThePastYear[0]?.fullPath}
                name={balanceSheetForThePastYear[0]?.fileName}
                value={balanceSheetForThePastYear}
              />
            ),
          },
          {
            name: t('memberApply.flow1.incomeStatement'),
            content: (
              <ConfirmUploadInfo
                path={incomeStatementForThePastYear[0]?.fullPath}
                name={incomeStatementForThePastYear[0]?.fileName}
                value={incomeStatementForThePastYear}
              />
            ),
          },
          {
            name: t('memberApply.flow1.cashFlowStatement'),
            content: (
              <ConfirmUploadInfo
                path={cashFlowStatementForThePastYear[0]?.fullPath}
                name={cashFlowStatementForThePastYear[0]?.fileName}
                value={cashFlowStatementForThePastYear}
              />
            ),
          },
          {
            name: t('memberApply.flow1.financialDoc'),
            content: (
              <ConfirmUploadInfo
                path={financialStatementOrFinancialVisa[0]?.fullPath}
                name={financialStatementOrFinancialVisa[0]?.fileName}
                value={financialStatementOrFinancialVisa}
              />
            ),
          },
          {
            name: t('memberApply.flow1.creditReport'),
            content: (
              <ConfirmUploadInfo
                path={
                  creditReportOfUnitedCreditInformationCenterForThePastSixMonths[0]
                    ?.fullPath
                }
                name={
                  creditReportOfUnitedCreditInformationCenterForThePastSixMonths[0]
                    ?.fileName
                }
                value={
                  creditReportOfUnitedCreditInformationCenterForThePastSixMonths
                }
              />
            ),
          },
          {
            name: t('memberApply.flow1.noRefundProof'),
            content: (
              <ConfirmUploadInfo
                path={noRefundRecordForThePastSixMonths[0]?.fullPath}
                name={noRefundRecordForThePastSixMonths[0]?.fileName}
                value={noRefundRecordForThePastSixMonths}
              />
            ),
          },
          {
            name: t('memberApply.flow1.noViolationsCertificate'),
            content: (
              <ConfirmUploadInfo
                path={
                  certificateOfNoIllegalTaxOwingForThePastSixMonths[0]?.fullPath
                }
                name={
                  certificateOfNoIllegalTaxOwingForThePastSixMonths[0]?.fileName
                }
                value={certificateOfNoIllegalTaxOwingForThePastSixMonths}
              />
            ),
          },
        ]}
      />
      {!isRecommendMember && (
        <>
          <BlockHeading>
            {t('memberApply.common.DevelopmentSupportingDoc')}
          </BlockHeading>
          <Table
            columns={[
              {
                dataIndex: 'name',
                width: isTabletM ? '130px' : '192px',
              },
              {
                dataIndex: 'content',
                width: '100%',
              },
            ]}
            dataSource={[
              {
                name: t('memberApply.flow2.productionExp'),
                content: (
                  <ConfirmUploadInfo
                    path={productionExperience[0]?.fullPath}
                    name={productionExperience[0]?.fileName}
                    value={productionExperience}
                  />
                ),
              },
              {
                name: t('memberApply.flow2.awardRecord'),
                content: (
                  <ConfirmUploadInfo
                    path={awardRecord[0]?.fullPath}
                    name={awardRecord[0]?.fileName}
                    value={awardRecord}
                  />
                ),
              },
              {
                name: t('memberApply.flow2.vcRecord'),
                content: (
                  <ConfirmUploadInfo
                    path={ventureCapitalExperience[0]?.fullPath}
                    name={ventureCapitalExperience[0]?.fileName}
                    value={ventureCapitalExperience}
                  />
                ),
              },
              {
                name: t('memberApply.flow2.ipRecord'),
                content: (
                  <ConfirmUploadInfo
                    path={
                      intellectualPropertyRightsDevelopmentExperience[0]
                        ?.fullPath
                    }
                    name={
                      intellectualPropertyRightsDevelopmentExperience[0]
                        ?.fileName
                    }
                    value={intellectualPropertyRightsDevelopmentExperience}
                  />
                ),
              },
            ]}
          />
        </>
      )}
      {!isRecommendMember && (
        <>
          <BlockHeading>
            {t('memberApply.common.ProofIntCooperation')}
          </BlockHeading>
          <Table
            columns={[
              {
                dataIndex: 'name',
                width: isTabletM ? '130px' : '192px',
              },
              {
                dataIndex: 'content',
                width: '100%',
              },
            ]}
            dataSource={[
              {
                name: t('memberApply.flow3.intMarket'),
                content: (
                  <ConfirmUploadInfo
                    path={internationalMarket[0]?.fullPath}
                    name={internationalMarket[0]?.fileName}
                    value={internationalMarket}
                  />
                ),
              },
              {
                name: t('memberApply.flow3.intJointVenture'),
                content: (
                  <ConfirmUploadInfo
                    path={internationalJointVenture[0]?.fullPath}
                    name={internationalJointVenture[0]?.fileName}
                    value={internationalJointVenture}
                  />
                ),
              },
            ]}
          />
        </>
      )}
      {subsidiesInThePastThreeYears.length > 0 && (
        <>
          <BlockHeading>
            {t('memberApply.common.subsidiesThreeYears')}
          </BlockHeading>
          <ConfirmSubsidy source={subsidiesInThePastThreeYears} />
        </>
      )}
    </div>
  );
}

export { Flow5 };
