import { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import LoginPage from 'page/LoginPage';
import LogoutPage from 'page/LogoutPage';
import MemberPage from 'page/MemberPage';
import MemberApplicationPage from 'page/MemberApplicationPage';
import RegisterPage from 'page/RegisterPage';
import WantSendPage from 'page/WantSendPage';
import ForgotPage from 'page/ForgotPage';
import FormPage from 'page/FormPage';
import useUser from 'hook/useUser';
import { planId, planOption } from 'constant/map';
import RecommendMemberApplicationPage from 'page/RecommendMemberApplicationPage';
import { TaiccaContext } from 'App';

function AppRoute() {
  const user = useUser();
  const isLogin = !!user;
  const isVerified = isLogin && user.verified;
  const { hasThroughRecommendApply, disableApply } = useContext(TaiccaContext);

  return (
    <Switch>
      <Route exact path="/">
        {isLogin ? <Redirect to="/member" /> : <LoginPage />}
      </Route>
      <Route path="/register">
        {isLogin ? <Redirect to="/member" /> : <RegisterPage />}
      </Route>
      <Route path="/logout">
        <LogoutPage />
      </Route>
      <Route path="/forgot">
        {isLogin ? <Redirect to="/member" /> : <ForgotPage />}
      </Route>
      <Route path="/member">
        {!isLogin ? <Redirect to="/" /> : <MemberPage />}
      </Route>
      <Route path="/member-application">
        {!isLogin || !disableApply ? (
          <Redirect to="/" />
        ) : (
          <MemberApplicationPage />
        )}
      </Route>
      <Route path="/recommend-member-application">
        {!isLogin || !hasThroughRecommendApply ? (
          <Redirect to="/" />
        ) : (
          <RecommendMemberApplicationPage />
        )}
      </Route>
      <Route path="/send-scheme">
        {!isLogin || !isVerified ? (
          <Redirect to="/" />
        ) : (
          <WantSendPage></WantSendPage>
        )}
      </Route>
      <Route path={['/scheme/support/series/:pid', '/scheme/support/series']}>
        {!isLogin || !isVerified ? (
          <Redirect to="/" />
        ) : (
          <FormPage planId={planId.SUPPORT} planOption={planOption.SERIES} />
        )}
      </Route>
      <Route
        path={[
          '/scheme/support/documentary/:pid',
          '/scheme/support/documentary',
        ]}
      >
        {!isLogin || !isVerified ? (
          <Redirect to="/" />
        ) : (
          <FormPage
            planId={planId.SUPPORT}
            planOption={planOption.DOCUMENTARY}
          />
        )}
      </Route>
      <Route path={['/scheme/support/anime/:pid', '/scheme/support/anime']}>
        {!isLogin || !isVerified ? (
          <Redirect to="/" />
        ) : (
          <FormPage planId={planId.SUPPORT} planOption={planOption.ANIME} />
        )}
      </Route>
      <Route
        path={['/scheme/cooperative/series/:pid', '/scheme/cooperative/series']}
      >
        {!isLogin || !isVerified ? (
          <Redirect to="/" />
        ) : (
          <FormPage
            planId={planId.COOPERATIVE}
            planOption={planOption.SERIES}
          />
        )}
      </Route>
      <Route
        path={[
          '/scheme/cooperative/documentary/:pid',
          '/scheme/cooperative/documentary',
        ]}
      >
        {!isLogin || !isVerified ? (
          <Redirect to="/" />
        ) : (
          <FormPage
            planId={planId.COOPERATIVE}
            planOption={planOption.DOCUMENTARY}
          />
        )}
      </Route>
      <Route
        path={['/scheme/cooperative/anime/:pid', '/scheme/cooperative/anime']}
      >
        {!isLogin || !isVerified ? (
          <Redirect to="/" />
        ) : (
          <FormPage planId={planId.COOPERATIVE} planOption={planOption.ANIME} />
        )}
      </Route>
      <Route path={['/scheme/marketing/:pid', '/scheme/marketing']}>
        {!isLogin || !isVerified ? (
          <Redirect to="/" />
        ) : (
          <FormPage
            planId={planId.MARKETING}
            planOption={planOption.MARKETING}
          />
        )}
      </Route>
      {/** 404暫時導回首頁 */}
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
}

export default AppRoute;
