import { FormHeading } from 'component/Typography';
import Table from 'component/Table';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import ConfirmUploadInfo from 'component/ConfirmInfo/ConfirmUploadInfo';
import { firstStepExtraNameList } from 'app/Form/Builder';

function DataCheckForm(props) {
  const { formShapeList, formData } = props;
  const { t } = useTranslation();
  const isMobileL = useMedia('(max-width: 425px)');
  const columns = [
    {
      dataIndex: 'name',
      width: isMobileL ? '100px' : '192px',
    },
    {
      dataIndex: 'content',
      width: '100%',
    },
  ];
  const fieldNameMap = formShapeList.flat().reduce((prev, current) => {
    if (firstStepExtraNameList.includes(current.name)) return prev;
    return {
      ...prev,
      [current.name]: current,
    };
  }, {});
  // 這個是拉 api 把資料攤平
  const dataSource = Object.keys(formData.values)
    .map((x) => {
      const fieldInfo = fieldNameMap[x];
      if (!fieldInfo) return undefined;
      const isFile = fieldInfo.type === 'file';
      const isImage = fieldInfo.type === 'image';
      const isRadio = fieldInfo.type === 'radio';
      const value = formData.values[x];
      const newValue = (() => {
        if (isFile || isImage) {
          if (value.length === 0) return '';
          return (
            <ConfirmUploadInfo
              path={value[0].fileLink || value[0].fullPath}
              name={value[0]?.fileName}
              value={value}
            />
          );
        }
        if (isRadio) {
          const theMap = {
            originalStory: t('scheme.radioOption.originalStory'),
            adaptedText: t('scheme.radioOption.adaptedText'),
            historicalAdaptation: t('scheme.radioOption.historicalAdaptation'),
          };
          return theMap[value] || value;
        }
        return value;
      })();
      return {
        name: fieldInfo.title,
        content: newValue,
      };
    })
    .filter((x) => x);
  return (
    <>
      <FormHeading>{t('common.flow.docConfirmation')}</FormHeading>
      <Table columns={columns} dataSource={dataSource}></Table>
    </>
  );
}

export default DataCheckForm;
