import RadioGroup from 'component/Input/Radio/RadioGroup';
import Radio from 'component/Input/Radio/Radio';
import SubsidyForm from 'component/SubsidyForm';
import { FormHeading } from 'component/Typography';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

const SubsidyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const Action = styled.div`
  color: ${(props) => props.theme.color.primary500};
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
`;
const newSubsidy = () => ({
  year: '',
  projectName: '',
  subsidyAgency: '',
  subsidyAmount: '',
});

function Flow4(props) {
  const { t } = useTranslation();
  const { formData, onChange } = props;
  const { hasSubsidy } = formData.values;

  return (
    <div>
      <FormHeading>{t('memberApply.flow4.needToSent')}</FormHeading>
      <RadioGroup
        value={formData.values.hasSubsidy}
        onChange={(hasSubsidy) => {
          onChange({
            ...formData,
            values: {
              ...formData.values,
              hasSubsidy: hasSubsidy,
              subsidiesInThePastThreeYears: hasSubsidy
                ? formData.values.subsidiesInThePastThreeYears
                : [],
            },
          });
        }}
        name={'number'}
      >
        <Radio value={true}>{t('memberApply.flow4.subsidized')}</Radio>
        <Radio value={false}>{t('memberApply.flow4.noSubsidy')}</Radio>
      </RadioGroup>
      {hasSubsidy && (
        <SubsidyContainer>
          {formData.values.subsidiesInThePastThreeYears.map(
            (subsidy, index) => {
              return (
                <SubsidyForm
                  data={subsidy}
                  formData={formData}
                  onChange={(newSubsidies) => {
                    onChange({
                      ...formData,
                      values: {
                        ...formData.values,
                        subsidiesInThePastThreeYears: newSubsidies,
                      },
                    });
                  }}
                  key={index}
                  number={index}
                />
              );
            },
          )}
          <Action
            onClick={() => {
              if (!onChange) return;
              onChange({
                ...formData,
                values: {
                  ...formData.values,
                  subsidiesInThePastThreeYears: [
                    ...formData.values.subsidiesInThePastThreeYears,
                    newSubsidy(),
                  ],
                },
              });
            }}
          >
            {t('common.flow.addOne')}
          </Action>
        </SubsidyContainer>
      )}
    </div>
  );
}

export { Flow4 };
