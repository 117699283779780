import { useState, useEffect } from 'react';
import CameraLayout from 'layout/CameraLayout';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { FormContentWrapper } from 'layout/shared';
import { useQuery, useMutation } from 'react-query';
import { PageHeading } from 'component/Typography';
import { FormHeading } from 'component/Typography';
import { FormFooter } from 'layout/shared';
import SendScheme from 'component/SendScheme';
import SendSchemeItem from 'component/SendSchemeItem';
import { Button } from 'component/Button';
import { useTranslation } from 'react-i18next';
import { planId, planOption } from 'constant/map';
import useUser from 'hook/useUser';
import { useDebouncedCallback } from 'use-debounce';
import api from 'api';

const SendSchemeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 42px 0;
  ${(props) => props.theme.media.tablet_M} {
    margin: 20px 0;
    gap: 15px;
    flex-direction: column;
    align-items: center;
  }
`;

const RefferalInput = styled.input`
  background: #ffffff;
  border: 1px solid #bbbbbf;
  padding: 10px;
  width: 100%;
  &:focus {
    outline: none;
  }
`;

const CheckReferralCode = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: #d32810;
  margin: 25px 0 0 0;
`;

function WantSendPage() {
  const { t } = useTranslation();
  const user = useUser();
  const { verified, email, role } = user;
  const [isRecommend, setIsRecommend] = useState(false);
  const [refferalCode, setRefferalCode] = useState('');
  const [select, setSelect] = useState('');
  const checkRefferalCodeMutation = useMutation(api.checkRefferalCode);

  useEffect(() => {
    if (role === 'recommend') {
      setIsRecommend(true);
    }
  }, []);

  const handleChange = (id) => (value) => {
    setSelect(value);
  };
  const planSettingQuery = useQuery('planSetting', api.planSetting, {
    select: (data) => {
      return data.filter((d) => d.status);
    },
  });
  const { isLoading, data } = planSettingQuery;
  const history = useHistory();
  const schemePath = () => {
    localStorage.setItem('refferalCode', refferalCode);
    history.push('scheme/' + select);
    document.querySelector('html').scrollTo(0, 0);
  };

  const showScheme = () => {
    setIsRecommend(false);
  };

  function checkCode() {
    const code = document.querySelector('#codeInput');
    const result = document.querySelector('#result');
    checkRefferalCodeMutation.mutateAsync(code.value).then((res) => {
      result.innerText = res.data.msg;
      if (res.data.status) {
        setRefferalCode(code.value);
      }
    });
  }

  const processChanges = useDebouncedCallback(checkCode, 500);

  return (
    <CameraLayout
      progressBlock={<PageHeading>{t('header.delivery')}</PageHeading>}
    >
      <FormContentWrapper>
        <FormHeading>
          {isRecommend ? t('scheme.referral') : t('scheme.heading')}
        </FormHeading>
        <SendSchemeContainer>
          {(() => {
            if (isLoading) {
              return <div>Loading...</div>;
            }
            if (isRecommend) {
              return (
                <div style={{ maxWidth: '300px', width: '100%' }}>
                  <RefferalInput
                    placeholder={t('scheme.referral')}
                    id="codeInput"
                    onInput={processChanges}
                  />
                  <CheckReferralCode id="result"></CheckReferralCode>
                </div>
              );
            } else {
              return data.map((item) => {
                if (item.id === planId.SUPPORT)
                  return (
                    <SendScheme
                      key={planId.SUPPORT}
                      selectedValue={select}
                      onChange={handleChange(planId.SUPPORT)}
                      title={t('scheme.scriptSupport')}
                    >
                      <SendSchemeItem
                        pathValue={`support/${planOption.SERIES}`}
                        title={t('scheme.longFilmTvSeries.heading')}
                      ></SendSchemeItem>
                      <SendSchemeItem
                        pathValue={`support/${planOption.DOCUMENTARY}`}
                        title={t('scheme.documentarySeries.heading')}
                      ></SendSchemeItem>
                      <SendSchemeItem
                        pathValue={`support/${planOption.ANIME}`}
                        title={t('scheme.animation.heading')}
                      ></SendSchemeItem>
                    </SendScheme>
                  );
                if (item.id === planId.COOPERATIVE)
                  return (
                    <SendScheme
                      key={planId.COOPERATIVE}
                      selectedValue={select}
                      onChange={handleChange(planId.COOPERATIVE)}
                      title={t('scheme.cooperative')}
                    >
                      <SendSchemeItem
                        pathValue={`cooperative/${planOption.SERIES}`}
                        title={t('scheme.longFilmTvSeries.heading')}
                      ></SendSchemeItem>
                      <SendSchemeItem
                        pathValue={`cooperative/${planOption.DOCUMENTARY}`}
                        title={t('scheme.documentarySeries.heading')}
                      ></SendSchemeItem>
                      <SendSchemeItem
                        pathValue={`cooperative/${planOption.ANIME}`}
                        title={t('scheme.animation.heading')}
                      ></SendSchemeItem>
                    </SendScheme>
                  );
                if (item.id === planId.MARKETING)
                  return (
                    <SendScheme
                      key={planId.MARKETING}
                      selectedValue={select}
                      onChange={handleChange(planId.MARKETING)}
                      pathValue={planOption.MARKETING}
                      title={t('scheme.marketing')}
                    ></SendScheme>
                  );
                return null;
              });
            }
          })()}
        </SendSchemeContainer>
      </FormContentWrapper>
      <FormFooter>
        <Button color="secondary" onClick={history.goBack}>
          {t('common.cancelGoBack')}
        </Button>
        <Button
          disabled={isRecommend ? refferalCode === '' : select === ''}
          onClick={isRecommend ? showScheme : schemePath}
        >
          {t('common.nextStep')}
        </Button>
      </FormFooter>
    </CameraLayout>
  );
}

export default WantSendPage;
