import { useState } from 'react';
import styled from 'styled-components/macro';
import logoUrl from 'asset/logo.png';
import logoRwdUrl from 'asset/logo_rwd.png';
import { Link } from 'react-router-dom';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { writeStorage } from '@rehooks/local-storage';
import storageKey from 'constant/storageKey';
import MenuBurger from 'component/MenuBurger';
import { useMedia } from 'react-use';
import { useEffect } from 'react';

const Container = styled.div`
  display: flex;
  position: relative;
  height: 100px;
  max-width: ${(props) => props.theme.widthBoundary2};
  margin-top: 40px;
  ${(props) => props.theme.media.tablet_L} {
    height: 60px;
    margin-top: 0px;
    justify-content: space-between;
    align-items: center;
  }
  ${(props) => props.theme.media.mobile_L} {
    margin: 0 -20px;
    height: 40px;
    justify-content: center;
  }
  ${(props) => props.theme.media.mobile_S} {
    height: 40px;
  }
`;
const LogoWrapper = styled.div`
  flex: none;
  width: 282px;
  padding: 20px 44px 28px 28px;
  ${(props) => props.theme.media.tablet_L} {
    width: 160px;
    padding: 20px;
  }
  ${(props) => props.theme.media.mobile_L} {
    width: 150px;
    padding: 0px;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
const LinkContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: auto;
  padding: 34px 34px 44px;
  border: 1px solid ${(props) => props.theme.color.bluegray000};
  border-bottom: 0;
  gap: 32px;
`;
const RwdLoginMenu = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: ${(props) => (props.isActive ? '100vh' : '0px')};
  background: ${(props) =>
    props.isActive
      ? 'rgba(255, 255, 255, 0.9)'
      : props.theme.color.bluegray900};
  backdrop-filter: ${(props) => (props.isActive ? 'blur(20px)' : 'blur(0px)')};
  -webkit-backdrop-filter: ${(props) =>
    props.isActive ? 'blur(20px)' : 'blur(0px)'};
  transform: translateZ(0);
  z-index: 98;
  transition: 0.8s;
  overflow: hidden;
`;
const RwdLoginMenuGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  ${(props) => props.theme.media.tablet_L} {
    padding: 73px 70px;
  }
  ${(props) => props.theme.media.mobile_M} {
    padding: 73px 45px;
  }
  ${(props) => props.theme.media.mobile_S} {
    padding: 73px 25px;
  }
`;
const RwdLoginMenuLink = styled(Link)`
  color: ${(props) => props.theme.color.bluegray800};
  font-size: 18px;
  font-weight: 500;
  line-height: 19px;
  text-decoration: none;
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  transition: 0.2s;
  &:hover {
    color: ${(props) => props.theme.color.primary600};
  }
`;
const MenuLink = styled(Link)`
  color: ${(props) => props.theme.color.bluegray050};
  text-decoration: none;
`;
const I18nAction = styled.div`
  color: ${(props) =>
    props.isActive
      ? props.theme.color.bluegray800
      : props.theme.color.bluegray050};
  cursor: pointer;
`;

function LoginMenu() {
  const { t } = useTranslation();
  const targetLocale = i18n.language;
  const setDefaultLocale = () => {
    i18n.changeLanguage(targetLocale);
    writeStorage(storageKey.LOCALE, targetLocale);
  };
  const setLocale = () => {
    const targetLocale = i18n.language === 'en-US' ? 'zh-TW' : 'en-US';
    i18n.changeLanguage(targetLocale);
    writeStorage(storageKey.LOCALE, targetLocale);
  };
  const [isRwd, setIsRwd] = useState(false);
  const isTablet_L = useMedia('(max-width: 768px)');
  const isMobile_L = useMedia('(max-width: 425px)');
  useEffect(setDefaultLocale, [targetLocale]);

  return (
    <Container>
      <LogoWrapper>
        {isMobile_L ? (
          <Link to="/">
            <img src={logoRwdUrl} alt="Logo" />
          </Link>
        ) : (
          <Link to="/">
            <img src={logoUrl} alt="Logo" />
          </Link>
        )}
      </LogoWrapper>
      {!isTablet_L && (
        <LinkContainer>
          {/* <MenuLink to={'/component'}>{t('menu.componentPage')}</MenuLink> */}
          <MenuLink to={'/'}>{t('menu.goFrontPage')}</MenuLink>
          <MenuLink to={'/'}>{t('menu.contactUs')}</MenuLink>
          <I18nAction onClick={setLocale}>{t('locale')}</I18nAction>
        </LinkContainer>
      )}
      {isTablet_L && (
        <MenuBurger
          isActive={isRwd}
          onClick={() => {
            if (isRwd) {
              setIsRwd(false);
              return;
            }
            setIsRwd(true);
            return;
          }}
        />
      )}
      {isTablet_L && (
        <RwdLoginMenu isActive={isRwd}>
          <RwdLoginMenuGroup>
            {/* <RwdLoginMenuLink isActive={isRwd} to={'/component'}>
              {t('menu.componentPage')}
            </RwdLoginMenuLink> */}
            <RwdLoginMenuLink isActive={isRwd} to={'/'}>
              {t('menu.goFrontPage')}
            </RwdLoginMenuLink>
            <RwdLoginMenuLink isActive={isRwd} to={'/'}>
              {t('menu.contactUs')}
            </RwdLoginMenuLink>
            <I18nAction isActive={isRwd} onClick={setLocale}>
              {t('locale')}
            </I18nAction>
          </RwdLoginMenuGroup>
        </RwdLoginMenu>
      )}
    </Container>
  );
}

export { LoginMenu };
