import Modal, { hideDialog, showDialog } from 'component/Modal/Modal';
import styled from 'styled-components/macro';
import Card from 'app/MemberApplicationDialog/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const DialogWrapper = styled.div`
  position: relative;
  max-height: 100%;
  padding: 53px 64px 74px;
  background: ${(props) => props.theme.color.bluegray000};
  overflow: auto;
  animation: ${(props) => (props.$isOpen ? showDialog : hideDialog)}
    ${(props) => props.$animationDuration}ms ease-in-out forwards;
  ${(props) => props.theme.media.laptop_M} {
    padding: 53px 50px 74px;
  }
  ${(props) => props.theme.media.laptop_S} {
    padding: 53px 30px 74px;
  }
  ${(props) => props.theme.media.tablet_L} {
    padding: 53px 30px 64px;
  }
  ${(props) => props.theme.media.mobile_L} {
    padding: 43px 20px 54px;
  }
`;

const Button = styled.button`
  position: absolute;
  inset: 53px 64px auto auto;
  color: ${(props) => props.theme.color.bluegray400};
  background: none;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    filter: brightness(0.9);
  }
  ${(props) => props.theme.media.laptop_M} {
    inset: 53px 50px auto auto;
  }
  ${(props) => props.theme.media.laptop_S} {
    inset: 53px 30px auto auto;
  }
  ${(props) => props.theme.media.mobile_L} {
    inset: 43px 20px auto auto;
  }
`;

const Heading = styled.p`
  font-size: 28px;
  font-weight: 600;
  line-height: 39px;
  text-align: center;
  ${(props) => props.theme.media.mobile_L} {
    font-size: 24px;
  }
`;

const SubHeading = styled.p`
  margin: 16px 0 43px;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  ${(props) => props.theme.media.tablet_L} {
    margin: 16px 0 32px;
  }
  ${(props) => props.theme.media.mobile_L} {
    font-size: 14px;
  }
`;

const CardWrapper = styled.div`
  display: flex;
  gap: 32px;
  ${(props) => props.theme.media.laptop_M} {
    gap: 28px;
  }
  ${(props) => props.theme.media.laptop_S} {
    gap: 20px;
  }
  ${(props) => props.theme.media.tablet_M} {
    flex-direction: column;
  }
`;

const MemberApplicationDialog = ({ isOpen, onClose, animationDuration }) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      animationDuration={animationDuration}
    >
      <DialogWrapper $isOpen={isOpen} $animationDuration={animationDuration}>
        <Button onClick={onClose}>
          <FontAwesomeIcon
            icon={['far', 'times']}
            style={{ width: '14px', height: '38px' }}
          />
        </Button>
        <Heading>{t('memberApply.dialog.heading')}</Heading>
        <SubHeading>{t('memberApply.dialog.headingDesc')}</SubHeading>
        <CardWrapper>
          <Card disable title={t('memberApply.dialog.card.mouApply')}>
            {t('memberApply.dialog.card.mouApplyDesc')}
          </Card>
          <Card
            title={t('memberApply.dialog.card.mouRecommendApply')}
            hasInput={true}
          >
            {t('memberApply.dialog.card.mouRecommendApplyDesc')}
          </Card>
        </CardWrapper>
      </DialogWrapper>
    </Modal>
  );
};

MemberApplicationDialog.defaultProps = {
  isOpen: false,
  title: null,
  onClose: () => {},
};

export default MemberApplicationDialog;
