import { useEffect, useMemo } from 'react';
import FormControl from 'component/FormControl';
import Input from 'component/Input/Input';
import { FormHeading } from 'component/Typography';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';

function Flow1(props) {
  const { t } = useTranslation();
  const { formData, onChange } = props;
  // eslint-disable-next-line
  const initialValues = useMemo(() => formData.values, []);
  const formik = useFormik({
    initialValues: initialValues,
    initialTouched: formData.flow1 ? formData.flow1.touched : {},
    validationSchema: Yup.object({
      applicant: Yup.string().required(t('common.validation.required')),
      email: Yup.string().required(t('common.validation.required')),
    }),
    validateOnMount: true,
    validateOnChange: false,
  });
  const { values, touched, errors } = formik;
  useEffect(() => {
    if (onChange) {
      onChange({ ...formData, values, flow1: { touched, errors } });
    }
    // eslint-disable-next-line
  }, [onChange, values, touched, errors]);

  return (
    <>
      <FormHeading>{t('header.forgotPage')}</FormHeading>
      <FormControl
        isRequired
        label={t('common.flow.applicant')}
        placement="top-left"
        isError={!!(touched.applicant && errors.applicant)}
        errorMessage={errors.applicant}
      >
        <Input
          placeholder={t('common.pleaseEnter')}
          id="applicant"
          {...formik.getFieldProps('applicant')}
        />
      </FormControl>
      <FormControl
        isRequired
        label={t('common.flow.loginMailbox')}
        placement="top-left"
        isError={!!(touched.email && errors.email)}
        errorMessage={errors.email}
      >
        <Input
          placeholder={t('common.pleaseEnter')}
          id="email"
          {...formik.getFieldProps('email')}
        />
      </FormControl>
    </>
  );
}

export { Flow1 };
