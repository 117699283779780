import React from 'react';
import styled, { css } from 'styled-components/macro';

const ErrorMessage = styled.div`
  flex: 0 0 auto;
  color: ${(props) => props.theme.color.primary500};
`;

const LabelWrapper = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
`;

const Tip = styled.div`
  color: ${(props) => props.theme.color.bluegray900};
  display: flex;
  align-items: flex-end;
`;

const Desc = styled.div`
  white-space: pre-line;
  color: ${(props) => props.theme.color.bluegray500};
  ${(props) => props.theme.media.mobile_L} {
    font-size: 12px;
  }
`;

const RequiredSign = styled.span`
  color: ${(props) => props.theme.color.primary500};
`;

const topCommonStyle = css`
  flex-direction: column;
  & > *:not(:first-child) {
    margin-top: 8px;
  }
`;
const topLeftStyle = css`
  ${topCommonStyle}
`;
const topStyle = css`
  align-items: center;
  ${topCommonStyle}
`;
const topRightStyle = css`
  align-items: flex-end;
  ${topCommonStyle}
`;
const bottomCommonStyle = css`
  flex-direction: column-reverse;
  & > *:not(:first-child) {
    margin-bottom: 8px;
  }
`;
const bottomLeftStyle = css`
  ${bottomCommonStyle}
`;
const bottomStyle = css`
  align-items: center;
  ${bottomCommonStyle}
`;
const bottomRightStyle = css`
  align-items: flex-end;
  ${bottomCommonStyle}
`;
const leftStyle = css`
  align-items: center;
  & > *:not(:first-child) {
    margin-left: 8px;
  }
`;
const rightStyle = css`
  align-items: center;
  flex-direction: row-reverse;
  & > *:not(:first-child) {
    margin-right: 8px;
  }
`;

const placementStyleMap = {
  'top-left': topLeftStyle,
  top: topStyle,
  'top-right': topRightStyle,
  left: leftStyle,
  right: rightStyle,
  'bottom-left': bottomLeftStyle,
  bottom: bottomStyle,
  'bottom-right': bottomRightStyle,
};

const StyledFormControl = styled.div`
  display: inline-flex;
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  ${(props) => props.theme.media.laptop_M} {
    font-size: 14px;
  }
  ${(props) => props.theme.media.mobile_M} {
    font-size: 12px;
  }

  ${(props) => {
    if (props.placement) {
      return placementStyleMap[props.placement];
    }
    return null;
  }}
`;

/**
 * `FormControl` 讓我們可以將 form input 所需要的共同前後文特性獨立出來管理，
 * 使被 control 的子元件之間的樣式能夠保持一致性。
 * 例如在 form input 元件 `TextField`, `Switch`, `Checkbox` 當中，
 * 將 `label`, `required`, `error` ...等邏輯與樣式獨立出來藉由 FormControl 來管理。
 */
const FormControl = ({
  label,
  tip,
  desc,
  children,
  placement,
  isError,
  errorMessage,
  isRequired,
  ...props
}) => {
  return (
    <StyledFormControl placement={placement} {...props}>
      <LabelWrapper>
        <div>
          {isRequired && <RequiredSign>＊</RequiredSign>}
          {label}
        </div>
        {tip && <Tip>{tip}</Tip>}
      </LabelWrapper>
      {desc && <Desc dangerouslySetInnerHTML={{ __html: desc }} />}
      {React.Children.map(children, (child) => {
        if (!child) return null;
        return React.cloneElement(child, {
          isError:
            child.props.isError === undefined ? isError : child.props.isError,
        });
      })}
      {isError !== false && errorMessage && (
        <ErrorMessage>{errorMessage}</ErrorMessage>
      )}
    </StyledFormControl>
  );
};

FormControl.defaultProps = {
  label: '',
  tip: null,
  desc: null,
  placement: 'top-left',
  errorMessage: '',
  isRequired: false,
};

export default FormControl;
export { StyledFormControl };
