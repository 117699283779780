import { useMemo, useContext } from 'react';
import CameraLayout from 'layout/CameraLayout';
import { Sidebar, FormContentWrapper, FormFooter } from 'layout/shared';
import * as RegisterForm from 'app/Form/Register';
import { PageHeading } from 'component/Typography';
import useTabs from 'hook/useTabs';
import { Button } from 'component/Button';
import { useTranslation } from 'react-i18next';
import api from 'api';
import { useMutation } from 'react-query';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Error } from 'component/Typography';
import styled from 'styled-components/macro';
import { useMedia } from 'react-use';
import StepAction from 'component/StepAction';
import storageKey from 'constant/storageKey';
import { TaiccaContext } from 'App';

const formatTel = (area = '', body = '', extension = '') => {
  let number = '';
  if (area) number += `${area}-`;
  number += `${body}`;
  number += ` ${extension}`;
  return number;
};

const MessageBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  ${(props) => props.theme.media.tablet_S} {
    width: 100%;
  }
`;
const StepBlock = styled.div`
  position: relative;
  padding-bottom: 13px;
`;
const StepHeading = styled.div`
  text-align: center;
  font-size: 17px;
  font-weight: 700;
`;
const StepNumber = styled.div`
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 17px;
  color: ${(props) => props.theme.color.primary600};
`;

function RegisterPage() {
  const { t } = useTranslation();
  const isTablet_S = useMedia('(max-width: 551px)');
  const { tabIndex } = useContext(TaiccaContext);
  const locale = localStorage.getItem(storageKey.LOCALE);
  const registerMutation = useMutation(api.register);
  const { touched, values, errors } = useFormikContext();
  const flow1Status = useMemo(() => {
    const flow1Keys = [
      'email',
      'applicant',
      'password',
      'confirmPassword',
      'contactAddress',
      'tax',
      'creationDate',
      'numberOfEmployees',
      'capital',
      'registrantAddress',
      'companyIntroduction',
    ];
    const flow1TouchedEntries = Object.entries(touched).filter(([key, _]) =>
      flow1Keys.includes(key),
    );
    const flow1Touched = Object.fromEntries(flow1TouchedEntries);
    const flow1ErrorEntries = Object.entries(errors).filter(([key, _]) =>
      flow1Keys.includes(key),
    );
    const flow1Error = Object.fromEntries(flow1ErrorEntries);
    if (Object.keys(flow1Touched).length === 0) return 'none';
    if (
      Object.keys(flow1Touched).length > 0 &&
      Object.keys(flow1Error).length > 0
    )
      return 'warning';
    return 'complete';
  }, [touched, errors]);
  const flow2Status = useMemo(() => {
    const flow2Keys = ['personInCharge', 'fax', 'contactPerson'];
    const flow2TouchedEntries = Object.entries(touched).filter(([key, _]) =>
      flow2Keys.includes(key),
    );
    const flow2Touched = Object.fromEntries(flow2TouchedEntries);
    const flow2ErrorEntries = Object.entries(errors).filter(([key, _]) =>
      flow2Keys.includes(key),
    );
    const flow2Error = Object.fromEntries(flow2ErrorEntries);
    if (Object.keys(flow2Touched).length === 0) return 'none';
    if (
      Object.keys(flow2Touched).length > 0 &&
      Object.keys(flow2Error).length > 0
    )
      return 'warning';
    return 'complete';
  }, [touched, errors]);

  const tabOptions = useMemo(
    () => [
      {
        value: 'tab1',
        label: t('tab.register.companyBasicInfo'),
        panel: <RegisterForm.Flow1 />,
        status: flow1Status,
      },
      {
        value: 'tab2',
        label: t('tab.register.contactInformation'),
        panel: <RegisterForm.Flow2 />,
        status: flow2Status,
      },
      {
        value: 'tab3',
        label: t('tab.common.docConfirmation'),
        panel: <RegisterForm.Flow3 />,
        status: 'none',
      },
    ],
    [t, flow1Status, flow2Status],
  );
  const { Tabs, TabPanels, next, prev, isFirstTab, isLastTab } = useTabs({
    options: tabOptions,
    align: 'right',
    hasNumber: true,
    lock: registerMutation.isSuccess,
  });
  return (
    <CameraLayout
      progressBlock={
        !isTablet_S ? (
          <Sidebar>
            <PageHeading>{t('header.registerPage')}</PageHeading>
            {Tabs}
          </Sidebar>
        ) : (
          <StepBlock>
            {!registerMutation.isSuccess && (
              <StepAction onClick={prev} arrow={'left'}></StepAction>
            )}
            <StepHeading>{t('header.registerPage')}</StepHeading>
          </StepBlock>
        )
      }
    >
      <FormContentWrapper>
        {isTablet_S && !registerMutation.isSuccess && (
          <StepNumber>{`step ${tabIndex + 1} of ${
            tabOptions.length
          }`}</StepNumber>
        )}
        {registerMutation.isSuccess ? <RegisterForm.Flow4 /> : TabPanels}
      </FormContentWrapper>
      {!registerMutation.isSuccess &&
        (isTablet_S ? (
          isLastTab ? (
            <FormFooter>
              <MessageBlock>
                {registerMutation.isError && (
                  <Error>{registerMutation.error}</Error>
                )}
                <Button
                  onClick={() => {
                    const newData = {
                      ...values,
                      contactAddress: {
                        ...values.contactAddress,
                        city:
                          locale === 'en-US'
                            ? '-1'
                            : values.contactAddress.city,
                        villagesAndTowns:
                          locale === 'en-US'
                            ? '-1'
                            : values.contactAddress.villagesAndTowns,
                      },
                      registrantAddress: {
                        ...values.registrantAddress,
                        city:
                          locale === 'en-US'
                            ? '-1'
                            : values.registrantAddress.city,
                        villagesAndTowns:
                          locale === 'en-US'
                            ? '-1'
                            : values.registrantAddress.villagesAndTowns,
                      },
                      personInCharge: {
                        ...values.personInCharge,
                        tel: formatTel(
                          values.personInCharge.tel.area,
                          values.personInCharge.tel.body,
                          values.personInCharge.tel.extension,
                        ),
                      },
                      contactPerson: {
                        ...values.contactPerson,
                        tel: formatTel(
                          values.contactPerson.tel.area,
                          values.contactPerson.tel.body,
                          values.contactPerson.tel.extension,
                        ),
                      },
                      fax: formatTel(
                        values.fax.area,
                        values.fax.body,
                        values.fax.extension,
                      ),
                    };
                    registerMutation.mutate(newData);
                  }}
                  disabled={
                    flow1Status !== 'complete' || flow2Status !== 'complete'
                  }
                  color="primary"
                  fullWidth
                >
                  {t('common.sendForm')}
                </Button>
              </MessageBlock>
            </FormFooter>
          ) : (
            <FormFooter>
              <Button onClick={next} color="primary" fullWidth>
                {t('common.nextStep')}
              </Button>
            </FormFooter>
          )
        ) : isLastTab ? (
          <FormFooter>
            <Button onClick={prev} color="secondary" disabled={isFirstTab}>
              {t('common.prevStep')}
            </Button>
            <MessageBlock>
              {registerMutation.isError && (
                <Error>{registerMutation.error}</Error>
              )}
              <Button
                onClick={() => {
                  const newData = {
                    ...values,
                    contactAddress: {
                      ...values.contactAddress,
                      city:
                        locale === 'en-US' ? '-1' : values.contactAddress.city,
                      villagesAndTowns:
                        locale === 'en-US'
                          ? '-1'
                          : values.contactAddress.villagesAndTowns,
                    },
                    registrantAddress: {
                      ...values.registrantAddress,
                      city:
                        locale === 'en-US'
                          ? '-1'
                          : values.registrantAddress.city,
                      villagesAndTowns:
                        locale === 'en-US'
                          ? '-1'
                          : values.registrantAddress.villagesAndTowns,
                    },
                    personInCharge: {
                      ...values.personInCharge,
                      tel: formatTel(
                        values.personInCharge.tel.area,
                        values.personInCharge.tel.body,
                        values.personInCharge.tel.extension,
                      ),
                    },
                    contactPerson: {
                      ...values.contactPerson,
                      tel: formatTel(
                        values.contactPerson.tel.area,
                        values.contactPerson.tel.body,
                        values.contactPerson.tel.extension,
                      ),
                    },
                    fax: formatTel(
                      values.fax.area,
                      values.fax.body,
                      values.fax.extension,
                    ),
                  };
                  registerMutation.mutate(newData);
                }}
                disabled={
                  flow1Status !== 'complete' || flow2Status !== 'complete'
                }
                color="primary"
              >
                {t('common.sendForm')}
              </Button>
            </MessageBlock>
          </FormFooter>
        ) : (
          <FormFooter>
            <Button onClick={prev} color="secondary" disabled={isFirstTab}>
              {t('common.prevStep')}
            </Button>
            <Button onClick={next} color="primary">
              {t('common.nextStep')}
            </Button>
          </FormFooter>
        ))}
    </CameraLayout>
  );
}
function FormikWrapper() {
  const { t } = useTranslation();
  const locale = localStorage.getItem(storageKey.LOCALE);
  return (
    <Formik
      initialValues={{
        applicant: '',
        email: '',
        password: '',
        confirmPassword: '',
        contactAddress: {
          city: '',
          villagesAndTowns: '',
          street: '',
        },
        tax: '',
        creationDate: '',
        numberOfEmployees: '',
        capital: '',
        registrantAddress: {
          city: '',
          villagesAndTowns: '',
          street: '',
        },
        companyIntroduction: '',
        personInCharge: {
          name: '',
          tel: {
            area: '',
            body: '',
            extension: '',
          },
          phone: '',
          email: '',
        },
        fax: {
          area: '',
          body: '',
          extension: '',
        },
        contactPerson: {
          name: '',
          department: '',
          tel: {
            area: '',
            body: '',
            extension: '',
          },
          phone: '',
          email: '',
        },
      }}
      validationSchema={Yup.object({
        applicant: Yup.string().required(t('common.validation.required')),
        email: Yup.string()
          .required(t('common.validation.required'))
          .email(`Email ${t('common.wrongFormat')}`),
        password: Yup.string()
          .required(t('common.validation.required'))
          .min(8, `${t('common.passwordLengthMustNotBeLessThan')} 8`),
        confirmPassword: Yup.string()
          .required(t('common.validation.required'))
          .oneOf(
            [Yup.ref('password'), null],
            `${t('common.notMatchPassword')}`,
          ),
        contactAddress: Yup.object().shape({
          city: Yup.string().test(
            'chinese_required',
            `${t('common.validation.required')}`,
            (val) => {
              //英文版不檢查必填
              return locale === 'en-US' ? true : !!val;
            },
          ),
          villagesAndTowns: Yup.string().test(
            'chinese_required',
            `${t('common.validation.required')}`,
            (val) => {
              //英文版不檢查必填
              return locale === 'en-US' ? true : !!val;
            },
          ),
          street: Yup.string().required(t('common.validation.required')),
        }),
        tax: Yup.string().required(t('common.validation.required')),
        // .test(
        //   'number',
        //   `${t('common.mostBeNumber')}`,
        //   (val) => !isNaN(Number(val)),
        // )
        // .length(8, `${t('common.mustBeDigits', { value: '8' })}`),
        creationDate: Yup.string().required(t('common.validation.required')),
        numberOfEmployees: Yup.string()
          .required(t('common.validation.required'))
          .test(
            'number',
            `${t('common.mostBeNumber')}`,
            (val) => !isNaN(Number(val)),
          ),
        capital: Yup.string()
          .required(t('common.validation.required'))
          .test(
            'number',
            `${t('common.mostBeNumber')}`,
            (val) => !isNaN(Number(val)),
          ),
        registrantAddress: Yup.object().shape({
          city: Yup.string().test(
            'chinese_required',
            `${t('common.validation.required')}`,
            (val) => {
              //英文版不檢查必填
              return locale === 'en-US' ? true : !!val;
            },
          ),
          villagesAndTowns: Yup.string().test(
            'chinese_required',
            `${t('common.validation.required')}`,
            (val) => {
              //英文版不檢查必填
              return locale === 'en-US' ? true : !!val;
            },
          ),
          street: Yup.string().required(t('common.validation.required')),
        }),
        companyIntroduction: Yup.string().required(
          t('common.validation.required'),
        ),

        personInCharge: Yup.object().shape({
          name: Yup.string().required(t('common.validation.required')),
          tel: Yup.object().shape({
            area: Yup.string().test(
              'number',
              t('common.validation.numberRequired'),
              (val) => {
                if (!val) return true;
                return !isNaN(Number(val));
              },
            ),
            body: Yup.string().test(
              'number',
              t('common.validation.numberRequired'),
              (val) => {
                if (!val) return true;
                return !isNaN(Number(val));
              },
            ),
            extension: Yup.string().test(
              'number',
              t('common.validation.numberRequired'),
              (val) => {
                if (!val) return true;
                if (['*', '#'].includes(val)) return true;
                return !isNaN(Number(val));
              },
            ),
          }),
          phone: Yup.string()
            .test('number', t('common.validation.numberRequired'), (val) => {
              if (!val) return true;
              return !isNaN(Number(val));
            })
            .length(10, t('common.validation.phoneRequired')), // 驗證電話,
          email: Yup.string().email(t('common.validation.emailRequired')),
        }),
        fax: Yup.object().shape({
          area: Yup.string().test(
            'number',
            t('common.validation.numberRequired'),
            (val) => {
              if (!val) return true;
              return !isNaN(Number(val));
            },
          ),
          body: Yup.string().test(
            'number',
            t('common.validation.numberRequired'),
            (val) => {
              if (!val) return true;
              return !isNaN(Number(val));
            },
          ),
          extension: Yup.string().test(
            'number',
            t('common.validation.numberRequired'),
            (val) => {
              if (!val) return true;
              if (['*', '#'].includes(val)) return true;
              return !isNaN(Number(val));
            },
          ),
        }),
        contactPerson: Yup.object().shape({
          name: Yup.string().required(t('common.validation.required')),
          department: Yup.string().required(t('common.validation.required')),
          tel: Yup.object().shape({
            area: Yup.string()
              .required(t('common.validation.required'))
              .test('number', t('common.validation.numberRequired'), (val) => {
                if (!val) return true;
                return !isNaN(Number(val));
              }),
            body: Yup.string()
              .required(t('common.validation.required'))
              .test('number', t('common.validation.numberRequired'), (val) => {
                if (!val) return true;
                return !isNaN(Number(val));
              }),
            extension: Yup.string().test(
              'number',
              t('common.validation.numberRequired'),
              (val) => {
                if (!val) return true;
                if (['*', '#'].includes(val)) return true;
                return !isNaN(Number(val));
              },
            ),
          }),
          phone: Yup.string()
            .required(t('common.validation.required'))
            .test(
              'number',
              t('common.validation.numberRequired'),
              (val) => !isNaN(Number(val)),
            )
            .length(10, t('common.validation.phoneRequired')),
          email: Yup.string()
            .required(t('common.validation.required'))
            .email(t('common.validation.emailRequired')),
        }),
      })}
    >
      <RegisterPage />
    </Formik>
  );
}

export default FormikWrapper;
