import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import arrowRightUrl from 'asset/arrow_right.png';

const StyleSendScheme = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  transition: 0.19s;
  ${(props) => props.theme.media.laptop_M} {
    font-size: 20px;
  }
  ${(props) => props.theme.media.tablet_L} {
    font-size: 16px;
  }
`;

const SendSchemeItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  ${(props) => props.theme.media.tablet_M} {
    gap: 12px;
  }
`;

const SendSchemeItemGroup = styled.div`
  max-height: 0px;
  transition: max-height 0.8s;
`;

const Directory = styled.div`
  height: 150px;
  padding: 0 10px;
  text-align: center;
  white-space: pre-line;
  border: 1px solid
    ${(props) => {
      if (props.isOpen || props.isActive) return props.theme.color.primary500;

      return props.theme.color.bluegray400;
    }};
  ${StyleSendScheme}
  color: ${(props) => {
    if (props.isOpen || props.isActive) return props.theme.color.bluegray000;

    return props.theme.color.bluegray900;
  }};
  background: ${(props) =>
    props.isActive ? props.theme.color.primary500 : null};
  ${(props) => props.theme.media.laptop_L} {
    height: 130px;
  }
  svg {
    position: absolute;
    inset: auto auto 12px 50%;
    width: 10px;
    height: 16px;
    transform: translateX(-50%);
  }
  img {
    position: absolute;
    inset: auto 29px 29px auto;
    width: 54px;
    height: 10px;
    transition: 0.19s;
    filter: ${(props) => (props.isActive ? 'brightness(10)' : 'grayscale(1)')};
  }
`;

const SendSchemeGroup = styled.div`
  flex: 0 0 calc(33% - 13px); // 30 / 3 = 13.x
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow: hidden;
  transition: 0.19s;
  background: ${(props) =>
    props.isOpen ? props.theme.color.primary500 : null};
  ${(props) => props.theme.media.tablet_M} {
    flex: 0 0 100%;
    width: 100%;
  }

  ${SendSchemeItemGroup} {
    max-height: ${(props) => props.itemsHeight}px;
  }
`;

const SendScheme = ({
  selectedValue,
  onChange,
  title,
  pathValue,
  children,
  ...props
}) => {
  const containerHeight = useRef();
  const [itemsHeight, setItemsHeight] = useState(0);

  return (
    <SendSchemeGroup itemsHeight={itemsHeight} isOpen={itemsHeight !== 0}>
      <Directory
        itemsHeight={itemsHeight}
        isOpen={itemsHeight !== 0}
        isActive={pathValue === selectedValue}
        onClick={() => {
          if (!children) {
            if (onChange) onChange(pathValue);
            return;
          }
          const isExpend = itemsHeight > 0;
          setItemsHeight(isExpend ? 0 : containerHeight.current.clientHeight);
          return;
        }}
      >
        {title}
        {children == null && <img src={arrowRightUrl} alt="arrow" />}
        {children && <FontAwesomeIcon icon={['fas', 'sort-down']} />}
      </Directory>
      <SendSchemeItemGroup>
        <SendSchemeItems ref={containerHeight}>
          {React.Children.map(children, (child) =>
            React.cloneElement(child, {
              onClick: onChange,
              isChoose: child.props.pathValue === selectedValue,
            }),
          )}
        </SendSchemeItems>
      </SendSchemeItemGroup>
    </SendSchemeGroup>
  );
};

SendScheme.defaultProps = {
  title: '',
};

export default SendScheme;
