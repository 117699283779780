import { useState, useEffect } from 'react';

const useTimeoutValue = (value, options) => {
  const [delayValue, setDelayValue] = useState(value);
  const { delay = 0, exceptionList = [] } = options;

  useEffect(() => {
    if (exceptionList.includes(value)) {
      setDelayValue(value);
      return;
    }

    const tid = setTimeout(() => {
      setDelayValue(value);
    }, delay);

    return () => clearTimeout(tid);
    // eslint-disable-next-line
  }, [value]);

  return delayValue;
};

export default useTimeoutValue;
