import styled from 'styled-components/macro';

const FormRow = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
`;
const DownloadRow = styled.a`
  display: inline-flex;
  gap: 12px;
  align-items: center;
  cursor: pointer;
  color: ${(props) => props.theme.color.bluegray900} !important;
  text-decoration: none !important;
  &:hover {
    color: ${(props) => props.theme.color.primary600} !important;
  }
`;

export { FormRow, DownloadRow };
