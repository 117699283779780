import { useMutation } from 'react-query';
import api from 'api';
import { default as UploadBase } from 'component/Input/Upload';

const Upload = (props) => {
  const { onChange, type, ...rest } = props;
  const fileMutation = useMutation(api.uploadFile);
  const imageMutation = useMutation(api.uploadImage);
  const handleChange = async (x) => {
    if (!onChange) return;
    if (x.length === 0) {
      onChange([]);
      return;
    }
    const fd = new FormData();
    x.forEach((file) => {
      fd.append(type, file);
    });
    const { data: res } = await (type === 'file'
      ? fileMutation
      : imageMutation
    ).mutateAsync(fd);
    const { msg, status, ...data } = res;
    if (type === 'file') {
      onChange([data]);
      return;
    }
    const filePath = data.path;
    const filePathArray = filePath.split('/');
    const fileName = filePathArray[filePathArray.length - 1];
    onChange([{ ...data, fileName }]);
  };

  return fileMutation.isLoading ? (
    <div>loading...</div>
  ) : (
    <UploadBase
      {...rest}
      accept={type === 'file' ? '.pdf' : 'image/*'}
      onChange={handleChange}
    />
  );
};

export default Upload;
