import { default as DatePickerBase } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Input from './Input';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

function DatePicker(props) {
  const { value = '', onChange, isError, placeholder, ...rest } = props;
  const { t } = useTranslation();

  return (
    <DatePickerBase
      selected={value}
      onChange={onChange}
      dateFormat={t('common.dataFormat')}
      placeholder={placeholder}
      customInput={
        <Input
          placeholder={placeholder}
          suffix={<FontAwesomeIcon icon={['fas', 'calendar-day']} />}
          isError={isError}
        />
      }
      {...rest}
    />
  );
}

export default memo(DatePicker);
