import React from 'react';
import styled, { css } from 'styled-components/macro';
import Textarea from 'component/Input/Textarea';

const Desc = styled.div`
  margin: 8px 0;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.color.bluegray500};
  ${(props) => props.theme.media.laptop_M} {
    font-size: 14px;
  }
`;
const RadioWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  margin-top: 8px;
`;
const RequiredSign = styled.span`
  color: ${(props) => props.theme.color.primary500};
`;
const LabelWrapper = styled.div`
  font-size: 16px;
  line-height: 18px;
  ${(props) => props.theme.media.laptop_M} {
    font-size: 14px;
  }
  ${(props) => props.theme.media.mobile_M} {
    font-size: 12px;
  }
`;
const StyleRadioGroup = styled.div`
  margin-bottom: 24px;
  ${(props) => props.theme.media.laptop_M} {
    margin-bottom: 22px;
  }
  ${(props) => props.theme.media.mobile_M} {
    margin-bottom: 18px;
  }

  ${(props) =>
    props.hasTextarea
      ? css`
          max-width: 450px;
        `
      : null}
`;

const RadioGroup = (props) => {
  const {
    children,
    value,
    onChange,
    label,
    isRequired,
    hasTextarea,
    placeholder,
    desc,
    name,
    ...rest
  } = props;
  const handleOnClick = (targetValue) => {
    onChange(targetValue);
  };
  return (
    <StyleRadioGroup hasTextarea={hasTextarea} {...rest}>
      <LabelWrapper>
        <div>
          {isRequired && <RequiredSign>＊</RequiredSign>}
          {label}
        </div>
      </LabelWrapper>
      <RadioWrapper>
        {React.Children.map(children, (child) =>
          React.cloneElement(child, {
            onClick: () => handleOnClick(child.props.value),
            isChecked: child.props.value === value,
            name,
          }),
        )}
      </RadioWrapper>

      {desc && <Desc>{desc}</Desc>}
      {hasTextarea && <Textarea placeholder={placeholder}></Textarea>}
    </StyleRadioGroup>
  );
};

RadioGroup.defaultProps = {
  value: null,
  children: null,
  onChange: () => {},
};

export default RadioGroup;
