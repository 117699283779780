import { useEffect, useMemo } from 'react';
import FormControl from 'component/FormControl';
import Upload from 'app/Form/component/Upload';
import { FormHeading, Description } from 'component/Typography';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
// import * as Yup from 'yup';

function Flow2(props) {
  const { t } = useTranslation();
  const { formData, onChange } = props;
  // eslint-disable-next-line
  const initialValues = useMemo(() => formData.values, []);
  const formik = useFormik({
    initialValues: initialValues,
    initialTouched: formData.flow2 ? formData.flow2.touched : {},
    // validationSchema: Yup.object({
    //   productionExperience: Yup.array().length(
    //     1,
    //     t('common.validation.required'),
    //   ),
    //   awardRecord: Yup.array().length(1, t('common.validation.required')),
    //   ventureCapitalExperience: Yup.array().length(
    //     1,
    //     t('common.validation.required'),
    //   ),
    //   intellectualPropertyRightsDevelopmentExperience: Yup.array().length(
    //     1,
    //     t('common.validation.required'),
    //   ),
    // }),
  });

  const { values, touched } = formik;
  useEffect(() => {
    if (onChange) {
      // flow2 不依靠 formik 的驗證機制，這邊是自己實作的判斷
      // 至少填兩筆資料，否則錯誤
      const NEED_FILLED_COUNT = 2;
      const flow2Values = [
        values.productionExperience,
        values.awardRecord,
        values.ventureCapitalExperience,
        values.intellectualPropertyRightsDevelopmentExperience,
      ];
      const flow2FilledCount = flow2Values.filter(
        (value) => value.length > 0,
      ).length;
      const isFlow2Error = flow2FilledCount < NEED_FILLED_COUNT;
      onChange({
        ...formData,
        values,
        // 如果 errors 的 key 數量 > 0，則表示有錯誤，這邊隨便給他一個 key 讓他錯
        flow2: { touched, errors: isFlow2Error ? { error: '' } : {} },
      });
    }
    // eslint-disable-next-line
  }, [onChange, values, touched]);

  return (
    <div>
      <FormHeading>{t('memberApply.flow2.needToSent')}</FormHeading>
      <Description light>{t('common.description.atLeastTwo')}</Description>
      <Description light>{t('common.description.pdfFormatHint')}</Description>
      <FormControl
        desc={t('memberApply.flow2.productionExpDesc')}
        label={t('memberApply.flow2.productionExp')}
        placement="top-left"
        // isError={
        //   !!(touched.productionExperience && errors.productionExperience)
        // }
        // errorMessage={errors.productionExperience}
      >
        <Upload
          id={'productionExperience'}
          type={'file'}
          {...formik.getFieldProps('productionExperience')}
          value={
            formik.values['productionExperience'] &&
            formik.values['productionExperience'].map((x, i) => {
              const theField = formik.values['productionExperience'][i];
              return {
                name: theField.fileName,
                preview: theField.fullPath,
              };
            })
          }
          onChange={(x) => {
            formik.setFieldTouched('productionExperience', true);
            formik.setFieldValue('productionExperience', x);
          }}
        />
      </FormControl>
      <FormControl
        desc={t('memberApply.flow2.awardRecordDesc')}
        label={t('memberApply.flow2.awardRecord')}
        placement="top-left"
        // isError={!!(touched.awardRecord && errors.awardRecord)}
        // errorMessage={errors.awardRecord}
      >
        <Upload
          id={'awardRecord'}
          type={'file'}
          {...formik.getFieldProps('awardRecord')}
          value={
            formik.values['awardRecord'] &&
            formik.values['awardRecord'].map((x, i) => {
              const theField = formik.values['awardRecord'][i];
              return {
                name: theField.fileName,
                preview: theField.fullPath,
              };
            })
          }
          onChange={(x) => {
            formik.setFieldTouched('awardRecord', true);
            formik.setFieldValue('awardRecord', x);
          }}
        />
      </FormControl>
      <FormControl
        desc={t('memberApply.flow2.vcRecordDesc')}
        label={t('memberApply.flow2.vcRecord')}
        placement="top-left"
        // isError={
        //   !!(
        //     touched.ventureCapitalExperience && errors.ventureCapitalExperience
        //   )
        // }
        // errorMessage={errors.ventureCapitalExperience}
      >
        <Upload
          id={'ventureCapitalExperience'}
          type={'file'}
          {...formik.getFieldProps('ventureCapitalExperience')}
          value={
            formik.values['ventureCapitalExperience'] &&
            formik.values['ventureCapitalExperience'].map((x, i) => {
              const theField = formik.values['ventureCapitalExperience'][i];
              return {
                name: theField.fileName,
                preview: theField.fullPath,
              };
            })
          }
          onChange={(x) => {
            formik.setFieldTouched('ventureCapitalExperience', true);
            formik.setFieldValue('ventureCapitalExperience', x);
          }}
        />
      </FormControl>
      <FormControl
        desc={t('memberApply.flow2.ipRecordDesc')}
        label={t('memberApply.flow2.ipRecord')}
        placement="top-left"
        // isError={
        //   !!(
        //     touched.intellectualPropertyRightsDevelopmentExperience &&
        //     errors.intellectualPropertyRightsDevelopmentExperience
        //   )
        // }
        // errorMessage={errors.intellectualPropertyRightsDevelopmentExperience}
      >
        <Upload
          id={'intellectualPropertyRightsDevelopmentExperience'}
          type={'file'}
          {...formik.getFieldProps(
            'intellectualPropertyRightsDevelopmentExperience',
          )}
          value={
            formik.values['intellectualPropertyRightsDevelopmentExperience'] &&
            formik.values[
              'intellectualPropertyRightsDevelopmentExperience'
            ].map((x, i) => {
              const theField =
                formik.values[
                  'intellectualPropertyRightsDevelopmentExperience'
                ][i];
              return {
                name: theField.fileName,
                preview: theField.fullPath,
              };
            })
          }
          onChange={(x) => {
            formik.setFieldTouched(
              'intellectualPropertyRightsDevelopmentExperience',
              true,
            );
            formik.setFieldValue(
              'intellectualPropertyRightsDevelopmentExperience',
              x,
            );
          }}
        />
      </FormControl>
    </div>
  );
}

export { Flow2 };
