const mediaSize = {
  desktop: '@media only screen and (max-width: 1440px)',
  laptop_L: '@media only screen and (max-width: 1280px)',
  laptop_M: '@media only screen and (max-width: 1024px)',
  laptop_S: '@media only screen and (max-width: 834px)',
  tablet_L: '@media only screen and (max-width: 768px)',
  tablet_M: '@media only screen and (max-width: 678px)',
  tablet_S: '@media only screen and (max-width: 551px)',
  mobile_L: '@media only screen and (max-width: 425px)',
  mobile_M: '@media only screen and (max-width: 375px)',
  mobile_S: '@media only screen and (max-width: 320px)',
};

export default mediaSize;
