import { useEffect, useMemo, useContext } from 'react';
import FormControl from 'component/FormControl';
import Upload from 'app/Form/component/Upload';
import { FormHeading, Description } from 'component/Typography';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import storageKey from 'constant/storageKey';
import { TaiccaContext } from 'App';

function Flow1(props) {
  const { t } = useTranslation();
  const { formData, onChange } = props;
  const locale = localStorage.getItem(storageKey.LOCALE);
  const { hasThroughRecommendApply } = useContext(TaiccaContext);
  // eslint-disable-next-line
  const initialValues = useMemo(() => formData.values, []);
  const formik = useFormik({
    initialValues: initialValues,
    initialTouched: formData.flow1 ? formData.flow1.touched : {},
    validateOnMount: true,
    validationSchema: Yup.object({
      registrationDocuments: Yup.array().length(
        1,
        t('common.validation.required'),
      ),
      nonMainlandCapitalDeclaration: Yup.array().length(
        1,
        t('common.validation.required'),
      ),
      balanceSheetForThePastYear: Yup.array().length(
        1,
        t('common.validation.required'),
      ),
      incomeStatementForThePastYear: Yup.array().length(
        1,
        t('common.validation.required'),
      ),
      cashFlowStatementForThePastYear: Yup.array().length(
        1,
        t('common.validation.required'),
      ),
      financialStatementOrFinancialVisa: Yup.array().length(
        1,
        t('common.validation.required'),
      ),
      creditReportOfUnitedCreditInformationCenterForThePastSixMonths:
        Yup.array().length(1, t('common.validation.required')),
      noRefundRecordForThePastSixMonths: Yup.array().length(
        1,
        t('common.validation.required'),
      ),
      certificateOfNoIllegalTaxOwingForThePastSixMonths: Yup.array().length(
        1,
        t('common.validation.required'),
      ),
    }),
  });

  const { values, touched, errors } = formik;
  useEffect(() => {
    if (onChange) {
      onChange({ ...formData, values, flow1: { touched, errors } });
    }
    // eslint-disable-next-line
  }, [onChange, values, touched, errors]);

  return (
    <div>
      <FormHeading>{t('memberApply.flow1.needPrepare')}</FormHeading>
      <Description light>{t('common.description.confirmHint')}</Description>
      <Description light>{t('common.description.pdfFormatHint')}</Description>
      <FormControl
        isRequired
        desc={
          hasThroughRecommendApply
            ? t('memberApply.recommend.setRegisterDocDesc')
            : t('memberApply.flow1.setRegisterDocDesc')
        }
        label={t('memberApply.flow1.setRegisterDoc')}
        placement="top-left"
        isError={
          !!(touched.registrationDocuments && errors.registrationDocuments)
        }
        errorMessage={errors.registrationDocuments}
      >
        <Upload
          id={'registrationDocuments'}
          type={'file'}
          {...formik.getFieldProps('registrationDocuments')}
          value={
            formik.values['registrationDocuments'] &&
            formik.values['registrationDocuments'].map((x, i) => {
              const theField = formik.values['registrationDocuments'][i];
              return {
                name: theField.fileName,
                preview: theField.fullPath,
              };
            })
          }
          onChange={(x) => {
            formik.setFieldTouched('registrationDocuments', true);
            formik.setFieldValue('registrationDocuments', x);
          }}
        />
      </FormControl>
      <FormControl
        isRequired
        desc={t('memberApply.flow1.statementDesc', {
          value:
            locale === 'zh-TW'
              ? `<a target="_blank" href="https://docs.google.com/document/d/1l9hG8MRS8u82SmjtJivx9Y9Vl7pppBdb/export?format=doc" rel="noreferrer noopener">${t(
                  'memberApply.common.download',
                )}</a>`
              : `<a target="_blank" href="https://docs.google.com/document/d/1ZsJVSgzprsiIF5AM9-Xv-yFO5dsjnr58/export?format=doc" rel="noreferrer noopener">${t(
                  'memberApply.common.download',
                )}</a>`,
        })}
        label={t('memberApply.flow1.statement')}
        placement="top-left"
        isError={
          !!(
            touched.nonMainlandCapitalDeclaration &&
            errors.nonMainlandCapitalDeclaration
          )
        }
        errorMessage={errors.nonMainlandCapitalDeclaration}
      >
        <Upload
          id={'nonMainlandCapitalDeclaration'}
          type={'file'}
          {...formik.getFieldProps('nonMainlandCapitalDeclaration')}
          value={
            formik.values['nonMainlandCapitalDeclaration'] &&
            formik.values['nonMainlandCapitalDeclaration'].map((x, i) => {
              const theField =
                formik.values['nonMainlandCapitalDeclaration'][i];
              return {
                name: theField.fileName,
                preview: theField.fullPath,
              };
            })
          }
          onChange={(x) => {
            formik.setFieldTouched('nonMainlandCapitalDeclaration', true);
            formik.setFieldValue('nonMainlandCapitalDeclaration', x);
          }}
        />
      </FormControl>
      <FormControl
        isRequired
        placement="top-left"
        desc={t('memberApply.flow1.balanceSheetDesc')}
        label={t('memberApply.flow1.balanceSheet')}
        isError={
          !!(
            touched.balanceSheetForThePastYear &&
            errors.balanceSheetForThePastYear
          )
        }
        errorMessage={errors.balanceSheetForThePastYear}
      >
        <Upload
          id={'balanceSheetForThePastYear'}
          type={'file'}
          {...formik.getFieldProps('balanceSheetForThePastYear')}
          value={
            formik.values['balanceSheetForThePastYear'] &&
            formik.values['balanceSheetForThePastYear'].map((x, i) => {
              const theField = formik.values['balanceSheetForThePastYear'][i];
              return {
                name: theField.fileName,
                preview: theField.fullPath,
              };
            })
          }
          onChange={(x) => {
            formik.setFieldTouched('balanceSheetForThePastYear', true);
            formik.setFieldValue('balanceSheetForThePastYear', x);
          }}
        />
      </FormControl>
      <FormControl
        isRequired
        label={t('memberApply.flow1.incomeStatement')}
        placement="top-left"
        isError={
          !!(
            touched.incomeStatementForThePastYear &&
            errors.incomeStatementForThePastYear
          )
        }
        errorMessage={errors.incomeStatementForThePastYear}
      >
        <Upload
          id={'incomeStatementForThePastYear'}
          type={'file'}
          {...formik.getFieldProps('incomeStatementForThePastYear')}
          value={
            formik.values['incomeStatementForThePastYear'] &&
            formik.values['incomeStatementForThePastYear'].map((x, i) => {
              const theField =
                formik.values['incomeStatementForThePastYear'][i];
              return {
                name: theField.fileName,
                preview: theField.fullPath,
              };
            })
          }
          onChange={(x) => {
            formik.setFieldTouched('incomeStatementForThePastYear', true);
            formik.setFieldValue('incomeStatementForThePastYear', x);
          }}
        />
      </FormControl>
      <FormControl
        isRequired
        label={t('memberApply.flow1.cashFlowStatement')}
        placement="top-left"
        isError={
          !!(
            touched.cashFlowStatementForThePastYear &&
            errors.cashFlowStatementForThePastYear
          )
        }
        errorMessage={errors.cashFlowStatementForThePastYear}
      >
        <Upload
          id={'cashFlowStatementForThePastYear'}
          type={'file'}
          {...formik.getFieldProps('cashFlowStatementForThePastYear')}
          value={
            formik.values['cashFlowStatementForThePastYear'] &&
            formik.values['cashFlowStatementForThePastYear'].map((x, i) => {
              const theField =
                formik.values['cashFlowStatementForThePastYear'][i];
              return {
                name: theField.fileName,
                preview: theField.fullPath,
              };
            })
          }
          onChange={(x) => {
            formik.setFieldTouched('cashFlowStatementForThePastYear', true);
            formik.setFieldValue('cashFlowStatementForThePastYear', x);
          }}
        />
      </FormControl>
      <FormControl
        isRequired
        desc={
          hasThroughRecommendApply
            ? t('memberApply.recommend.financialDocDesc')
            : t('memberApply.flow1.financialDocDesc', {
                value:
                  locale === 'zh-TW'
                    ? `<a target="_blank" href="https://docs.google.com/document/d/1CrcSE_mNHJkE1A_y9IOc20thiQpS0qJm/export?format=doc" rel="noreferrer noopener">${t(
                        'memberApply.common.download',
                      )}</a>`
                    : `<a target="_blank" href="https://docs.google.com/document/d/1nvLwATFwbfx8WYJ9ghPfqfHqhrjSWu6A/export?format=doc" rel="noreferrer noopener">${t(
                        'memberApply.common.download',
                      )}</a>`,
              })
        }
        label={t('memberApply.flow1.financialDoc')}
        placement="top-left"
        isError={
          !!(
            touched.financialStatementOrFinancialVisa &&
            errors.financialStatementOrFinancialVisa
          )
        }
        errorMessage={errors.financialStatementOrFinancialVisa}
      >
        <Upload
          id={'financialStatementOrFinancialVisa'}
          type={'file'}
          {...formik.getFieldProps('financialStatementOrFinancialVisa')}
          value={
            formik.values['financialStatementOrFinancialVisa'] &&
            formik.values['financialStatementOrFinancialVisa'].map((x, i) => {
              const theField =
                formik.values['financialStatementOrFinancialVisa'][i];
              return {
                name: theField.fileName,
                preview: theField.fullPath,
              };
            })
          }
          onChange={(x) => {
            formik.setFieldTouched('financialStatementOrFinancialVisa', true);
            formik.setFieldValue('financialStatementOrFinancialVisa', x);
          }}
        />
      </FormControl>
      <FormControl
        isRequired
        desc={
          hasThroughRecommendApply
            ? t('memberApply.recommend.creditReportDesc')
            : t('memberApply.flow1.creditReportDesc')
        }
        label={t('memberApply.flow1.creditReport')}
        placement="top-left"
        isError={
          !!(
            touched.creditReportOfUnitedCreditInformationCenterForThePastSixMonths &&
            errors.creditReportOfUnitedCreditInformationCenterForThePastSixMonths
          )
        }
        errorMessage={
          errors.creditReportOfUnitedCreditInformationCenterForThePastSixMonths
        }
      >
        <Upload
          id={'creditReportOfUnitedCreditInformationCenterForThePastSixMonths'}
          type={'file'}
          {...formik.getFieldProps(
            'creditReportOfUnitedCreditInformationCenterForThePastSixMonths',
          )}
          value={
            formik.values[
              'creditReportOfUnitedCreditInformationCenterForThePastSixMonths'
            ] &&
            formik.values[
              'creditReportOfUnitedCreditInformationCenterForThePastSixMonths'
            ].map((x, i) => {
              const theField =
                formik.values[
                  'creditReportOfUnitedCreditInformationCenterForThePastSixMonths'
                ][i];
              return {
                name: theField.fileName,
                preview: theField.fullPath,
              };
            })
          }
          onChange={(x) => {
            formik.setFieldTouched(
              'creditReportOfUnitedCreditInformationCenterForThePastSixMonths',
              true,
            );
            formik.setFieldValue(
              'creditReportOfUnitedCreditInformationCenterForThePastSixMonths',
              x,
            );
          }}
        />
      </FormControl>
      <FormControl
        isRequired
        desc={t('memberApply.flow1.noRefundProofDesc')}
        label={t('memberApply.flow1.noRefundProof')}
        placement="top-left"
        isError={
          !!(
            touched.noRefundRecordForThePastSixMonths &&
            errors.noRefundRecordForThePastSixMonths
          )
        }
        errorMessage={errors.noRefundRecordForThePastSixMonths}
      >
        <Upload
          id={'noRefundRecordForThePastSixMonths'}
          type={'file'}
          {...formik.getFieldProps('noRefundRecordForThePastSixMonths')}
          value={
            formik.values['noRefundRecordForThePastSixMonths'] &&
            formik.values['noRefundRecordForThePastSixMonths'].map((x, i) => {
              const theField =
                formik.values['noRefundRecordForThePastSixMonths'][i];
              return {
                name: theField.fileName,
                preview: theField.fullPath,
              };
            })
          }
          onChange={(x) => {
            formik.setFieldTouched('noRefundRecordForThePastSixMonths', true);
            formik.setFieldValue('noRefundRecordForThePastSixMonths', x);
          }}
        />
      </FormControl>
      <FormControl
        isRequired
        desc={t('memberApply.flow1.noViolationsCertificateDesc')}
        label={t('memberApply.flow1.noViolationsCertificate')}
        placement="top-left"
        isError={
          !!(
            touched.certificateOfNoIllegalTaxOwingForThePastSixMonths &&
            errors.certificateOfNoIllegalTaxOwingForThePastSixMonths
          )
        }
        errorMessage={errors.certificateOfNoIllegalTaxOwingForThePastSixMonths}
      >
        <Upload
          id={'certificateOfNoIllegalTaxOwingForThePastSixMonths'}
          type={'file'}
          {...formik.getFieldProps(
            'certificateOfNoIllegalTaxOwingForThePastSixMonths',
          )}
          value={
            formik.values[
              'certificateOfNoIllegalTaxOwingForThePastSixMonths'
            ] &&
            formik.values[
              'certificateOfNoIllegalTaxOwingForThePastSixMonths'
            ].map((x, i) => {
              const theField =
                formik.values[
                  'certificateOfNoIllegalTaxOwingForThePastSixMonths'
                ][i];
              return {
                name: theField.fileName,
                preview: theField.fullPath,
              };
            })
          }
          onChange={(x) => {
            formik.setFieldTouched(
              'certificateOfNoIllegalTaxOwingForThePastSixMonths',
              true,
            );
            formik.setFieldValue(
              'certificateOfNoIllegalTaxOwingForThePastSixMonths',
              x,
            );
          }}
        />
      </FormControl>
    </div>
  );
}

export { Flow1 };
