/* eslint-disable react/forbid-prop-types */
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const activeStyle = css`
  color: ${(props) => props.theme.color.primary600};
  font-weight: 600;
  &:hover {
    color: ${(props) => props.theme.color.primary600};
  }
`;

const StyledTab = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  height: 22px;
  margin: 13px 0;
  transition: 0.2s;
  color: ${(props) => props.theme.color.bluegray300};
  font-size: 16px;
  line-height: 22px;
  ${(props) => props.theme.media.tablet_L} {
    margin: 10px 0;
    font-size: 14px;
  }
  ${(props) => props.theme.media.tablet_S} {
    height: auto;
    margin: 10px 0 0 0;
    justify-content: space-between;
    flex-direction: column;
  }
  ${(props) => props.theme.media.mobile_M} {
    font-size: 12px;
  }

  &:hover {
    color: ${(props) => props.theme.color.bluegray000};
  }

  ${(props) => (props.isActive ? activeStyle : null)}
`;

const NumberIcon = styled.div`
  flex: 0 0 24px;
  margin-left: 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px;
  position: relative;
  ${(props) => props.theme.media.tablet_S} {
    margin-left: 0;
  }

  &::after {
    content: '';
    border-left: dashed 1px ${(props) => props.theme.color.bluegray000};
    height: 24px;
    top: -24px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    ${(props) => props.theme.media.tablet_S} {
      content: none;
    }

    ${StyledTab}:first-child & {
      height: 32px;
      top: -32px;
      ${(props) => props.theme.media.tablet_S} {
        height: auto;
        inset: 50% auto auto 100%;
      }
    }
  }
  ${(props) => {
    const { isActive, status } = props;
    if (isActive)
      return css`
        background: ${(props) => props.theme.color.primary500};
        border-color: ${(props) => props.theme.color.primary500};
        color: ${(props) => props.theme.color.bluegray900};
      `;

    if (status === 'complete')
      return css`
        background: ${(props) => props.theme.color.bluegray900};
        border-color: ${(props) => props.theme.color.success};
        color: ${(props) => props.theme.color.success};
      `;

    if (status === 'warning')
      return css`
        background: ${(props) => props.theme.color.bluegray900};
        border-color: ${(props) => props.theme.color.warning};
        color: ${(props) => props.theme.color.warning};
      `;

    return css`
      background: ${(props) => props.theme.color.bluegray900};
      border: 1px solid ${(props) => props.theme.color.primary600};
      color: ${(props) => props.theme.color.bluegray000};
    `;
  }}
`;

const Tab = ({
  label,
  number,
  isActive,
  onClick,
  hasNumber,
  status,
  ...props
}) => {
  return (
    <StyledTab isActive={isActive} onClick={onClick} status={status} {...props}>
      <span>{label}</span>
      {hasNumber && (
        <NumberIcon isActive={isActive} status={status}>
          {isActive ? (
            number
          ) : status === 'complete' ? (
            <FontAwesomeIcon icon={['fas', 'check']} />
          ) : status === 'warning' ? (
            <FontAwesomeIcon icon={['fas', 'exclamation']} />
          ) : (
            number
          )}
        </NumberIcon>
      )}
    </StyledTab>
  );
};

Tab.defaultProps = {
  label: '',
  isActive: false,
  align: 'left',
  status: 'none',
  onClick: () => {},
};

export default Tab;
