import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

const FileActions = styled.div`
  display: flex;
  gap: 8px;
`;
const FileName = styled.div`
  font-size: 16px;
  line-height: 22px;
`;
const FileAction = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.color.primary600};
  text-decoration: auto !important;
  transition: 0.2s;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.color.primary800};
  }
`;

function ConfirmUploadInfo({ name, path, value }) {
  const { t } = useTranslation();
  const isFileUpload = value?.length !== 0;
  return (
    <>
      {isFileUpload ? (
        <FileActions key={path}>
          <FileName>{name}</FileName>
          <FileAction as="a" href={path} target="_blank" rel="noreferrer">
            {t('upload.preview')}
          </FileAction>
        </FileActions>
      ) : null}
    </>
  );
}

export default ConfirmUploadInfo;
