import styled, { css } from 'styled-components/macro';

const StyleRadio = styled.label`
  display: inline-block;
  position: relative;
  padding-left: 24px;
  color: ${(props) => props.theme.color.bluegray900};
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  ${(props) => props.theme.media.laptop_M} {
    font-size: 14px;
  }
  ${(props) => props.theme.media.mobile_M} {
    font-size: 12px;
  }
`;

const InputRadio = styled.input`
  position: absolute;
  opacity: 0;
`;

const Input = styled.input`
  max-width: 100%;
  width: 75px;
  outline: none;
  border: none;
  margin-left: 5px;
  border: 1px solid ${(props) => props.theme.color.bluegray300};
  background-color: ${(props) => props.theme.color.bluegray000};
  color: ${(props) => props.theme.color.bluegray900};
  font-size: 12px;
  cursor: text;
  box-sizing: border-box;
  transition: 0.3s;
  &:hover {
    border: 1px solid ${(props) => props.theme.color.bluegray500};
  }
`;

const RadioButtonIcon = styled.span`
  display: inline-block;
  position: absolute;
  inset: 50% auto auto 0;
  width: 16px;
  height: 16px;
  background: ${(props) => props.theme.color.bluegray000};
  border: 1px solid ${(props) => props.theme.color.bluegray300};
  border-radius: 50%;
  transform: translate(0, -50%);
  &::before {
    content: '';
    display: block;
    position: absolute;
    inset: 50% auto auto 50%;
    width: 6px;
    height: 6px;
    background: ${(props) => props.theme.color.bluegray000};
    border-radius: 50%;
    transform: translate(-50%, -50%);
    ${(props) => props.theme.media.laptop_M} {
      width: 5px;
      height: 5px;
    }
  }

  ${(props) =>
    props.checked &&
    css`
      background: ${(props) => props.theme.color.primary500};
      border: 1px solid ${(props) => props.theme.color.primary500};
      &::before {
        background: ${(props) => props.theme.color.primary000};
      }
    `};
  ${(props) => props.theme.media.laptop_M} {
    width: 14px;
    height: 14px;
  }
`;

const Radio = (props) => {
  const {
    children,
    hasInput,
    setOtherValue,
    isChecked,
    isDisabled,
    onClick,
    name,
    value,
    ...rest
  } = props;
  const handleInput = (e) => {
    setOtherValue(e.target.value);
  };
  return (
    <StyleRadio
      onClick={isDisabled ? null : onClick}
      $isDisabled={isDisabled}
      {...rest}
    >
      <InputRadio type="radio" value={value} name={name} />
      <RadioButtonIcon checked={isChecked} />
      {children}
      {hasInput && (
        <Input value={value} onChange={handleInput} type="text"></Input>
      )}
    </StyleRadio>
  );
};

export default Radio;
export { StyleRadio };
