import { useMemo } from 'react';
import FolderLayout from 'layout/FolderLayout';
import { PageHeading } from 'component/Typography';
import useTabs from 'hook/useTabs';
import { Sidebar, FormContentWrapper } from 'layout/shared';
import * as MemberForm from 'app/Form/Member';
import { useTranslation } from 'react-i18next';

function MemberPage() {
  const { t } = useTranslation();
  const tabOptions = useMemo(
    () => [
      {
        value: 'myRecord',
        label: t('tab.memberCenter.myRecord'),
        panel: (
          <>
            <FormContentWrapper>
              <MemberForm.MyRecord />
            </FormContentWrapper>
          </>
        ),
        status: 'none',
      },
      {
        value: 'performance',
        label: t('tab.memberCenter.performance'),
        panel: (
          <>
            <FormContentWrapper>
              <MemberForm.Performance />
            </FormContentWrapper>
          </>
        ),
        status: 'none',
      },
      {
        value: 'memberInfo',
        label: t('tab.memberCenter.memberInfo'),
        panel: (
          <>
            <FormContentWrapper>
              <MemberForm.MemberInfo />
            </FormContentWrapper>
          </>
        ),
        status: 'none',
      },
      {
        value: 'downloadCenter',
        label: t('tab.memberCenter.downloadCenter'),
        panel: (
          <>
            <FormContentWrapper>
              <MemberForm.DownloadCenter />
            </FormContentWrapper>
          </>
        ),
        status: 'none',
      },
      {
        value: 'changePassword',
        label: t('tab.memberCenter.changePassword'),
        panel: <MemberForm.ChangePassword />,
        status: 'none',
      },
    ],
    [t],
  );
  const { Tabs, TabPanels } = useTabs({
    options: tabOptions,
    align: 'left',
  });

  return (
    <FolderLayout
      progressBlock={
        <Sidebar align="left">
          <PageHeading hasStar>{t('header.memberPage')}</PageHeading>
          {Tabs}
        </Sidebar>
      }
    >
      {TabPanels}
    </FolderLayout>
  );
}

export default MemberPage;
