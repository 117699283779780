import { LoginMenu, Content, Footer } from 'layout/shared';
import styled, { css } from 'styled-components/macro';
import left_bottomImageUrl from 'asset/login/left_bottom.png';
import right_topImageUrl from 'asset/login/right_top.png';
import diamondUrl from 'asset/login/diamond.png';
import diamond_whiteUrl from 'asset/login/diamond_white.png';
import squareUrl from 'asset/login/square.png';
import loginUrl from 'asset/login/login.png';
import vectorUrl from 'asset/login/vector.png';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const OutsideContainer = styled.div`
  max-width: ${(props) => props.theme.widthBoundary2};
  border: solid 1px ${(props) => props.theme.color.bluegray000};
  padding-top: 20px;
  padding-left: 20px;
  ${(props) => props.theme.media.tablet_L} {
    padding: 20px 60px;
  }
  ${(props) => props.theme.media.tablet_L} {
    padding: 20px;
  }
  ${(props) => props.theme.media.mobile_L} {
    margin-top: 54px;
    padding: 0;
    border: none;
  }
`;
const Container = styled.div`
  position: relative;
  width: 100%;
  color: ${(props) => props.theme.color.bluegray000};
  border: solid 1px ${(props) => props.theme.color.bluegray000};
  border-right: 0;
  border-bottom: 0;
  ${(props) => props.theme.media.tablet_L} {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
  }
  &:before {
    content: '';
    display: block;
    padding-top: calc(782 / 1298 * 100%);
    ${(props) => props.theme.media.tablet_L} {
      content: none;
    }
  }
`;
const LoginFooter = styled.div`
  margin: -1px 21px 0;
  height: 20px;
  border: solid 1px ${(props) => props.theme.color.bluegray000};
  ${(props) => props.theme.media.mobile_L} {
    display: none;
  }
`;
const RightTopImageContainer = styled.div`
  position: absolute;
  right: 0;
  top: calc(24 / 782 * 100%);
  width: calc(214 / 1298 * 100%);

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
const LeftBottomImageContainer = styled.div`
  position: absolute;
  left: calc(-62 / 1298 * 100%);
  bottom: -1px;
  width: calc(568 / 1298 * 100%);
  display: flex;
  align-items: flex-end;
  pointer-events: none;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
const LeftBottomImageDiamond = styled.div`
  position: absolute;
  inset: 100% 100% auto auto;
  width: calc(44 / 1298 * 100%);
  filter: brightness(10);
  ${(props) => props.theme.media.tablet_L} {
    display: none;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
const RightBottomImageDiamond = styled.div`
  position: absolute;
  inset: 100% auto auto calc(100% - 20px);
  width: calc(44 / 1298 * 100%);
  filter: brightness(10);
  ${(props) => props.theme.media.tablet_L} {
    display: none;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
const RightBottomImageVector = styled.div`
  position: absolute;
  inset: auto calc(170 / 1298 * 100%) calc(120 / 782 * 100%) auto;
  width: calc(78 / 1298 * 100%);
  z-index: 3;
  ${(props) => props.theme.media.tablet_L} {
    display: none;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
const RightBottomImageSquare = styled.div`
  position: absolute;
  inset: auto 0 -3px auto;
  width: calc(375 / 1298 * 100%);
  ${(props) => props.theme.media.tablet_L} {
    display: none;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
const RightBottomImageLogin = styled.div`
  position: absolute;
  inset: auto calc(53 / 1298 * 100%) calc(40 / 782 * 100%) auto;
  width: calc(306 / 1298 * 100%);
  ${(props) => props.theme.media.tablet_L} {
    display: none;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
const LittleCube = styled.div`
  position: relative;
  width: calc(20 / 120 * 100%);
  height: calc(20 / 782 * 100%);
  border: solid 1px ${(props) => props.theme.color.bluegray000};
  transition: 0.2s;
  &:hover {
    background: ${(props) => props.theme.color.bluegray000};
  }
  &:nth-child(28) {
    &:before {
      content: '';
      display: block;
      position: absolute;
      inset: auto 0 0 auto;
      width: calc(29 / 20 * 100%);
      height: calc(39 / 20 * 100%);
      transform: translate(50%, 50%);
      background: url(${diamondUrl}) no-repeat center / cover;
      z-index: 2;
    }
  }
  &:nth-child(44) {
    &:before {
      content: '';
      display: block;
      position: absolute;
      inset: auto 0 0 auto;
      width: calc(24 / 20 * 100%);
      height: calc(31 / 20 * 100%);
      transform: translate(50%, 50%);
      background: url(${diamondUrl}) no-repeat center / cover;
      z-index: 2;
    }
  }
  &:nth-child(141) {
    &:before {
      content: '';
      display: block;
      position: absolute;
      inset: auto 0 0 auto;
      width: calc(30 / 20 * 100%);
      height: calc(40 / 20 * 100%);
      transform: translate(50%, 50%);
      background: url(${diamondUrl}) no-repeat center / cover;
      z-index: 2;
    }
  }
`;
const LittleCubeContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  width: calc(120 / 1298 * 100%);
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;

  ${LittleCube} {
    margin-left: -1px;
    margin-top: -1px;
  }
`;
const LoginHeading = styled.div`
  display: inline-block;
  position: relative;
  color: #fff;
  font-size: 68px;
  font-weight: 700;
  letter-spacing: 0.1px;
  white-space: nowrap;
  z-index: 97;
  ${(props) => props.theme.media.desktop} {
    font-size: calc(1vw * (68 / 16));
  }
  ${(props) => props.theme.media.mobile_L} {
    font-size: 32px;
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    height: 17px;
    inset: auto -18px -7px -5px;
    clip-path: polygon(0 0, 97% 0%, 100% 100%, 0% 100%);
    background: ${(props) => props.theme.color.primary500};
    transform: translate(0, -1px);
    z-index: -1;
    ${(props) => props.theme.media.tablet_L} {
      height: 10px;
      inset: auto -5px -6px -5px;
      clip-path: unset;
    }
    ${(props) => props.theme.media.mobile_L} {
      height: 7px;
      inset: auto -5px -4px -5px;
    }
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 19px;
    inset: auto -19px -7px -6px;
    clip-path: polygon(0 0, 97% 0%, 100% 100%, 0% 100%);
    background: #fff;
    z-index: -2;
    ${(props) => props.theme.media.tablet_L} {
      height: 12px;
      inset: auto -6px -6px -6px;
      clip-path: unset;
    }
    ${(props) => props.theme.media.mobile_L} {
      height: 9px;
      inset: auto -6px -4px -6px;
    }
  }
`;
const Intro = styled.div`
  margin-top: 10px;
  ${(props) => props.theme.media.tablet_L} {
    display: none;
  }
`;
const IntroHeading = styled.div`
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 10px;
  ${(props) => props.theme.lang.zh} {
    line-height: 1.6;
  }
  ${(props) => props.theme.lang.en} {
    line-height: 1.2;
  }
  ${(props) => props.theme.media.desktop} {
    font-size: calc(1vw * (30 / 16));
  }
`;
const SubIntro = styled.div`
  margin-top: 2.5%;
  font-size: 16px;
  line-height: 1.4;
  white-space: pre-line;
  ${(props) => props.theme.media.desktop} {
    font-size: calc(1vw * (16 / 16));
  }
`;
const Remind = styled.ul`
  display: flex;
  margin-top: 2.5%;
  padding-right: 8px;
  color: yellow;
  font-size: 14px;
  line-height: 1.4;
  text-shadow: 1px 0.5px 1px black;
  ${(props) => props.theme.media.desktop} {
    font-size: calc(1vw * (16 / 16));
  }
  svg {
    margin-top: 1.5%;
    margin-right: 4px;
    font-size: 12px;
    filter: drop-shadow(1px 0.5px 1px black);
    ${(props) => props.theme.media.desktop} {
      font-size: calc(1vw * (16 / 16));
    }
    ${(props) => props.theme.media.laptop_M} {
      margin-top: 0.5%;
    }
  }
`;
const TitleBlock = styled.div`
  position: absolute;
  top: calc(86 / 782 * 100%);
  left: calc(200 / 1298 * 100%);
  width: calc(300 / 1298 * 100%);
  ${(props) => props.theme.media.tablet_L} {
    position: unset;
    width: auto;
  }
  ${(props) => props.theme.media.laptop_M} {
    left: calc(150 / 1298 * 100%);
    width: calc(350 / 1298 * 100%);
  }
  ${(props) => props.theme.media.tablet_L} {
    left: 50%;
    width: auto;
  }
`;
const Window = styled.div`
  position: absolute;
  top: calc(86 / 782 * 100%);
  right: calc(132 / 1298 * 100%);
  width: calc(660 / 1298 * 100%);
  background: ${(props) => props.theme.color.bluegray900};
  ${(props) => props.theme.media.laptop_M} {
    width: calc(600 / 1298 * 100%);
    top: calc(40 / 782 * 100%);
    right: calc(193 / 1298 * 100%);
  }
  ${(props) => props.theme.media.tablet_L} {
    position: unset;
    margin-top: 20px;
    width: 100%;
  }
`;
const WindowHeaderCircle = styled.div`
  width: calc(18 / 660 * 100%);
  border-radius: 50%;
  border: solid 1px ${(props) => props.theme.color.bluegray000};
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
  ${(props) =>
    props.isPrimary &&
    css`
      background: ${props.theme.color.bluegray000};
    `}
`;
const WindowHeader = styled.div`
  display: flex;
  align-items: center;
  border: solid 1px ${(props) => props.theme.color.bluegray000};
  padding: 0 32px;
  ${(props) => props.theme.media.mobile_L} {
    display: none;
  }

  &:before {
    content: '';
    display: block;
    padding-top: calc(60 / 660 * 100%);
  }

  ${WindowHeaderCircle} {
    margin-right: 16px;
  }
`;
const WindowBody = styled.div`
  padding: calc(72 / 660 * 100%) calc(172 / 660 * 100%) calc(172 / 660 * 100%)
    calc(86 / 660 * 100%);
  margin-top: -1px;
  border: solid 1px ${(props) => props.theme.color.bluegray000};
  ${(props) => props.theme.media.laptop_L} {
    padding: calc(72 / 660 * 100%) calc(172 / 660 * 100%) calc(90 / 660 * 100%)
      calc(86 / 660 * 100%);
  }
  ${(props) => props.theme.media.laptop_M} {
    padding: calc(72 / 660 * 100%) calc(50 / 660 * 100%) calc(110 / 660 * 100%)
      calc(50 / 660 * 100%);
  }
  ${(props) => props.theme.media.laptop_S} {
    padding: calc(40 / 660 * 100%) calc(50 / 660 * 100%) calc(40 / 660 * 100%)
      calc(50 / 660 * 100%);
  }
  ${(props) => props.theme.media.tablet_L} {
    padding: 30px;
  }
  ${(props) => props.theme.media.tablet_S} {
    padding: 20px;
  }
  ${(props) => props.theme.media.mobile_L} {
    padding: 0;
    border: none;
  }
`;
const WindowFooter = styled.div`
  height: 20px;
  margin-top: -1px;
  border: solid 1px ${(props) => props.theme.color.bluegray000};
`;

function LoginLayout(props) {
  const { children } = props;
  const { t } = useTranslation();
  const istablet_L = useMedia('(max-width: 768px)');

  return (
    <>
      <Content>
        <LoginMenu />
        <OutsideContainer>
          <Container>
            {!istablet_L && (
              <LittleCubeContainer>
                {Array(500)
                  .fill()
                  .map((_, i) => (
                    <LittleCube key={i} />
                  ))}
              </LittleCubeContainer>
            )}

            {!istablet_L && (
              <LeftBottomImageContainer>
                <img src={left_bottomImageUrl} alt="" />
              </LeftBottomImageContainer>
            )}
            <LeftBottomImageDiamond>
              <img src={diamond_whiteUrl} alt="" />
            </LeftBottomImageDiamond>
            <RightBottomImageDiamond>
              <img src={diamond_whiteUrl} alt="" />
            </RightBottomImageDiamond>
            <RightBottomImageVector>
              <img src={vectorUrl} alt="" />
            </RightBottomImageVector>
            <RightBottomImageSquare>
              <img src={squareUrl} alt="" />
            </RightBottomImageSquare>
            <RightBottomImageLogin>
              <img src={loginUrl} alt="" />
            </RightBottomImageLogin>
            <TitleBlock>
              <LoginHeading>{t('header.loginPage')}</LoginHeading>
              <Intro>
                <IntroHeading>{t('loginPage.headingIntro')}</IntroHeading>
                <SubIntro>{t('loginPage.headingSubIntro')}</SubIntro>
                {/* <Remind>
                  <FontAwesomeIcon icon={['fas', 'exclamation-circle']} />
                  <li style={{ whiteSpace: 'pre-wrap' }}>
                    {t('loginPage.remind')}
                  </li>
                </Remind> */}
              </Intro>
            </TitleBlock>
            <Window>
              <WindowHeader>
                <WindowHeaderCircle isPrimary={true} />
                <WindowHeaderCircle />
                <WindowHeaderCircle />
              </WindowHeader>
              <WindowBody>{children}</WindowBody>
              {!istablet_L && <WindowFooter />}
            </Window>
            {!istablet_L && (
              <RightTopImageContainer>
                <img src={right_topImageUrl} alt="" />
              </RightTopImageContainer>
            )}
          </Container>
        </OutsideContainer>
        <LoginFooter />
      </Content>
      <Footer />
    </>
  );
}

export default LoginLayout;
