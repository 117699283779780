import { useEffect, useMemo } from 'react';
import FormControl from 'component/FormControl';
import Upload from 'app/Form/component/Upload';
import { FormHeading, Description } from 'component/Typography';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

function Flow3(props) {
  const { t } = useTranslation();
  const { formData, onChange } = props;
  // eslint-disable-next-line
  const initialValues = useMemo(() => formData.values, []);
  const formik = useFormik({
    initialValues: initialValues,
    initialTouched: formData.flow3 ? formData.flow3.touched : {},
    // validationSchema: Yup.object({
    //   internationalMarket: Yup.array().length(
    //     1,
    //     t('common.validation.required'),
    //   ),
    //   internationalJointVenture: Yup.array().length(
    //     1,
    //     t('common.validation.required'),
    //   ),
    // }),
  });

  const { values, touched, errors } = formik;
  useEffect(() => {
    if (onChange) {
      // flow2 不依靠 formik 的驗證機制，這邊是自己實作的判斷
      // 至少填兩筆資料，否則錯誤
      const NEED_FILLED_COUNT = 1;
      const flow3Values = [
        values.internationalMarket,
        values.internationalJointVenture,
      ];
      const flow3FilledCount = flow3Values.filter(
        (value) => value.length > 0,
      ).length;
      const isFlow3Error = flow3FilledCount < NEED_FILLED_COUNT;
      onChange({
        ...formData,
        values,
        // 如果 errors 的 key 數量 > 0，則表示有錯誤，這邊隨便給他一個 key 讓他錯
        flow3: { touched, errors: isFlow3Error ? { error: '' } : {} },
      });
      onChange({ ...formData, values, flow3: { touched, errors } });
    }
    // eslint-disable-next-line
  }, [onChange, values, touched, errors]);

  return (
    <div>
      <FormHeading>{t('memberApply.flow3.needToSent')}</FormHeading>
      <Description light>{t('common.description.atLeastOne')}</Description>
      <Description light>{t('common.description.pdfFormatHint')}</Description>
      <FormControl
        desc={t('memberApply.flow3.intMarketDesc')}
        label={t('memberApply.flow3.intMarket')}
        placement="top-left"
        // isError={!!(touched.internationalMarket && errors.internationalMarket)}
        // errorMessage={errors.internationalMarket}
      >
        <Upload
          id={'internationalMarket'}
          type={'file'}
          {...formik.getFieldProps('internationalMarket')}
          value={
            formik.values['internationalMarket'] &&
            formik.values['internationalMarket'].map((x, i) => {
              const theField = formik.values['internationalMarket'][i];
              return {
                name: theField.fileName,
                preview: theField.fullPath,
              };
            })
          }
          onChange={(x) => {
            formik.setFieldTouched('internationalMarket', true);
            formik.setFieldValue('internationalMarket', x);
          }}
        />
      </FormControl>
      <FormControl
        desc={t('memberApply.flow3.intJointVentureDesc')}
        label={t('memberApply.flow3.intJointVenture')}
        placement="top-left"
        // isError={
        //   !!(
        //     touched.internationalJointVenture &&
        //     errors.internationalJointVenture
        //   )
        // }
        // errorMessage={errors.internationalJointVenture}
      >
        <Upload
          id={'internationalJointVenture'}
          type={'file'}
          {...formik.getFieldProps('internationalJointVenture')}
          value={
            formik.values['internationalJointVenture'] &&
            formik.values['internationalJointVenture'].map((x, i) => {
              const theField = formik.values['internationalJointVenture'][i];
              return {
                name: theField.fileName,
                preview: theField.fullPath,
              };
            })
          }
          onChange={(x) => {
            formik.setFieldTouched('internationalJointVenture', true);
            formik.setFieldValue('internationalJointVenture', x);
          }}
        />
      </FormControl>
    </div>
  );
}

export { Flow3 };
