import { FormHeading, Description } from 'component/Typography';
import { Button } from 'component/Button';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import useUser from 'hook/useUser';
import { useTranslation } from 'react-i18next';

const Desc = styled(Description)`
  margin-bottom: 32px;
  white-space: pre-line;
`;

function Flow6() {
  const { t } = useTranslation();
  const user = useUser();
  const { email } = user;

  return (
    <div>
      <FormHeading>{t('common.flow.yourApplicationHasSent')}</FormHeading>
      <Desc>
        {t('common.flow.pleaseNoteYourEmail', {
          userEmail: email,
        })}
      </Desc>
      <div></div>
      <Button color="primary" as={Link} to="/member">
        {t('common.goSchemeHome')}
      </Button>
    </div>
  );
}

export { Flow6 };
