import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { useDropzone } from 'react-dropzone';
import { Button } from 'component/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import color from 'constant/color';
import { useTranslation } from 'react-i18next';
import { memo } from 'react';

const uploadDragoverStyle = css`
  border: 1px solid ${(props) => props.theme.color.primary600};
`;

const StyledUpload = styled.div`
  box-sizing: border-box;
  background: ${(props) => props.theme.color.bluegray050};
  border: 1px dashed ${(props) => props.theme.color.bluegray300};
  width: 100%;
  min-height: 60px;
  padding: 10px 24px;
  color: ${(props) => props.theme.color.bluegray300};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  ${(props) => props.theme.media.mobile_M} {
    padding: 10px;
  }

  ${(props) => props.isDragActive && uploadDragoverStyle}

  ${Button} {
    margin-left: 16px;
  }
`;

const FileInput = styled.input`
  display: none;
  opacity: 0;
`;

const FileActions = styled.div`
  display: flex;
  gap: 8px;
`;
const FileAction = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.color.primary600};
  text-decoration: auto !important;
  transition: 0.2s;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.color.primary800};
  }
`;
const FileName = styled.div`
  font-size: 16px;
  line-height: 22px;
`;

function Upload({ resetKey, children, onChange, accept, value, ...props }) {
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);
  const usedFiles = value || files;
  const resetInput = () => {
    setFiles([]);
    if (onChange) onChange([]);
  };
  const handleOnFileDrop = (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => ({
      ...file,
      preview: URL.createObjectURL(file),
    }));
    setFiles(newFiles);
    if (onChange) onChange(acceptedFiles);
  };

  // "fileId": "6198c5e0beefd",
  // "fullPath": "http://anbon.works/taicca/file/6198c5e0beefd",
  // "fileName": "台灣城市.xlsx",

  // "path":"uploads/2111200445316198b5ab71e20.jpg",
  // "fullPath":"http://anbon.works/taicca/uploads/2111200445316198b5ab71e20.jpg",

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleOnFileDrop,
    accept,
  });

  const { onClick: clickUploadZone, ...rootProps } = getRootProps();
  const { ...inputProps } = getInputProps();

  const isWaitFileUpload = usedFiles.length === 0;
  return isWaitFileUpload ? (
    <StyledUpload isDragActive={isDragActive} {...rootProps}>
      <FileInput
        type="file"
        isDragActive={isDragActive}
        {...inputProps}
        {...props}
      />
      {isDragActive ? (
        <FontAwesomeIcon color={color.primary600} icon={['fal', 'plus']} />
      ) : (
        <>
          {t('upload.dragHere')}
          <Button onClick={clickUploadZone} color="primary">
            {t('upload.selectFile')}
          </Button>
        </>
      )}
    </StyledUpload>
  ) : (
    usedFiles.map((file, i) => (
      <FileActions key={`${file.preview}${i}`}>
        <FileName>{file.name}</FileName>
        <FileAction as="a" href={file.preview} target="_blank" rel="noreferrer">
          {t('upload.preview')}
        </FileAction>
        <FileAction onClick={resetInput}>{t('upload.delete')}</FileAction>
      </FileActions>
    ))
  );
}

Upload.defaultProps = {
  resetKey: 0,
  accept: undefined,
  multiple: false,
  onChange: () => {},
};

export default memo(Upload);
