import { useState } from 'react';
import { FormHeading, Description } from 'component/Typography';
import styled, { css } from 'styled-components/macro';
import { TopButton } from 'layout/shared';
import { Button } from 'component/Button';
import { useTranslation } from 'react-i18next';
import SendCard from 'component/SendCard';
import MemberApplicationDialog from 'app/MemberApplicationDialog/MemberApplicationDialog';
import { Link } from 'react-router-dom';
import { useMedia } from 'react-use';
import useUser from 'hook/useUser';
import { useQuery } from 'react-query';
import api from 'api';
import { useMutation } from 'react-query';
import { planId as planIdMap } from 'constant/map';
import Popup from 'app/Form/component/Popup';

const Desc = styled(Description)`
  margin: 0 auto 32px;
  font-size: 16px;
  white-space: pre-line;
  ${(props) => props.theme.media.laptop_M} {
    font-size: 14px;
  }
  ${(props) => props.theme.media.mobile_L} {
    margin-bottom: 15px;
  }
`;
const leftAlignStyle = css`
  text-align: left;
`;
const centerAlignStyle = css`
  text-align: center;
`;
const rightAlignStyle = css`
  text-align: right;
`;
const alignStyle = {
  left: leftAlignStyle,
  center: centerAlignStyle,
  right: rightAlignStyle,
};
const DescWrapper = styled.div`
  position: absolute;
  inset: 50% 30px auto 30px;
  transform: translate(0, -50%);
  ${(props) => alignStyle[props.align] || alignStyle.center}
`;
const SendCardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const PopupTitle = styled.div`
  background: #000000;
  color: #ffffff;
  text-align: center;
  padding: 14px 0 8px 0;
`;

const PopupContent = styled.div`
  padding: 20px 25px 30px 25px;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;

function MyRecord() {
  const { t } = useTranslation();
  const user = useUser();
  const { verified, email, role } = user;
  const resendVerifyCodeMutation = useMutation(api.resendVerifyCode);
  const projectListQuery = useQuery(['projectList', 1], api.projectList); // Todo: page?
  const [isOpen, setIsOpen] = useState(false);
  const isTabletS = useMedia('(max-width: 551px)');
  const [verifyPopup, setVerifyPopup] = useState(false);
  const [contentModal, setContentModal] = useState('');

  const handleClick = () => {
    resendVerifyCodeMutation.mutate(
      { email: email },
      {
        onSuccess: (res) => {
          if (res.data.status === true) {
            setVerifyPopup(true);
            setContentModal(res.data.msg);
          }
        },
        onError: (error) => {
          console.error('Mutation failed:', error);
        },
      },
    );
    // .then((res) => {
    //   if (res.status === true) {
    //     setVerifyPopup(true);
    //     setContentModal(res.msg);
    //   }
    // });
  };

  return (
    <>
      {!isTabletS && (
        <FormHeading>{t('memberCenter.myRecord.heading')}</FormHeading>
      )}
      <TopButton
        as={Link}
        to="/send-scheme"
        size="middle"
        disabled={verified ? role !== 'mou' && role !== 'recommend' : true}
      >
        {t('memberCenter.myRecord.delivery')}
      </TopButton>
      {verified ? (
        role === 'mou' || role === 'recommend' ? (
          projectListQuery.isLoading ? (
            <div>Loading...</div>
          ) : projectListQuery.data.totalCount > 0 ? (
            <>
              <Description>
                {t('memberCenter.myRecord.information')}
              </Description>
              <SendCardList>
                {projectListQuery.data.items.map((item) => {
                  const path = (() => {
                    if (item.status.code === 'success') {
                      return item.action.link;
                    }
                    if (item.planId === planIdMap.MARKETING)
                      return `/scheme/${planIdMap[item.planId]}/${item.id}`;
                    return `/scheme/${planIdMap[item.planId]}/${
                      item.planOption
                    }/${item.id}`;
                  })();
                  return (
                    <SendCard
                      type={item.status.code}
                      enabled={item.action.enabled}
                      id={item.id}
                      title={item.title || t('common.none')}
                      scheme={item.sub_title}
                      time={item.create_date}
                      url={path}
                      key={path}
                      remarks={item.remarks}
                    />
                  );
                })}
              </SendCardList>
            </>
          ) : (
            <DescWrapper>
              <Desc>{t('memberCenter.myRecord.noListDescription')}</Desc>
            </DescWrapper>
          )
        ) : role !== 'normal' ? (
          <>
            <DescWrapper>
              <Desc>{t('memberCenter.myRecord.description')}</Desc>
              <Button onClick={() => setIsOpen(true)}>
                {t('memberCenter.myRecord.goUpload')}
              </Button>
              <MemberApplicationDialog
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                animationDuration={300}
              ></MemberApplicationDialog>
            </DescWrapper>
          </>
        ) : projectListQuery.isLoading ? (
          <div>Loading...</div>
        ) : projectListQuery.data.totalCount > 0 ? (
          <>
            <div>
              <DescWrapper
                style={{
                  position: 'relative',
                  inset: 0,
                  transform: 'translate(0,0)',
                  padding: '2rem 0',
                }}
              >
                <Desc>{t('memberCenter.myRecord.description')}</Desc>
                <Button onClick={() => setIsOpen(true)}>
                  {t('memberCenter.myRecord.goUpload')}
                </Button>
                <MemberApplicationDialog
                  isOpen={isOpen}
                  onClose={() => setIsOpen(false)}
                  animationDuration={300}
                ></MemberApplicationDialog>
              </DescWrapper>
            </div>
            {/* <Description>{t('memberCenter.myRecord.information')}</Description> */}
            <div>
              <SendCardList>
                {projectListQuery.data.items.map((item) => {
                  const path = (() => {
                    if (item.status.code === 'success') {
                      return item.action.link;
                    }
                    if (item.planId === planIdMap.MARKETING)
                      return `/scheme/${planIdMap[item.planId]}/${item.id}`;
                    return `/scheme/${planIdMap[item.planId]}/${
                      item.planOption
                    }/${item.id}`;
                  })();
                  return (
                    <SendCard
                      type={item.status.code}
                      id={item.id}
                      title={item.title || t('common.none')}
                      scheme={item.sub_title}
                      time={item.create_date}
                      enabled={item.action.enabled}
                      url={path}
                      key={path}
                      remarks={item.remarks}
                    />
                  );
                })}
              </SendCardList>
            </div>
          </>
        ) : (
          <></>
          // <DescWrapper>
          //   <Desc>{t('memberCenter.myRecord.noListDescription')}</Desc>
          // </DescWrapper>
        )
      ) : role === 'new' ? (
        <DescWrapper>
          <Desc>
            {t('memberCenter.myRecord.payAttentionMailbox', { value: email })}
          </Desc>
          <Button onClick={handleClick}>重新寄送驗證信</Button>
          <Popup trigger={verifyPopup} setTrigger={setVerifyPopup}>
            <PopupTitle></PopupTitle>
            <PopupContent>{contentModal}</PopupContent>
          </Popup>
        </DescWrapper>
      ) : role === 'normal' ? (
        <DescWrapper>
          <Desc>{t('memberCenter.myRecord.description')}</Desc>
          <Button onClick={() => setIsOpen(true)}>
            {t('memberCenter.myRecord.goUpload')}
          </Button>
          <MemberApplicationDialog
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            animationDuration={300}
          ></MemberApplicationDialog>
        </DescWrapper>
      ) : role === 'mou' || role === 'recommend' ? (
        <DescWrapper align="left">
          <Desc style={{ maxWidth: 504 }}>
            {t('memberCenter.myRecord.payAttentionMailboxNeedPrepare', {
              value: email,
            })}
          </Desc>
        </DescWrapper>
      ) : (
        <></>
      )}
    </>
  );
}

export { MyRecord };
